@import './templates/VickyBaumannFineart.scss';
@import './templates/Capri.scss';
@import './templates/Rose.scss';
@import './templates/Calm.scss';
@import './templates/NewShop.scss';

$darkPrimary: #111111;
$darkBackground: #212121;
$darkInactive: #888;
$bottomNavRowHeight: 60px;
$bottomNavRowHeightWithMargin: 70px;

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto !important;
  }
}

@font-face {
  font-family: 'signetonmedium';
  src: url('../assets/fonts/signeton-webfont.woff2') format('woff2'),
    url('../assets/fonts/signeton-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VickySymbols';
  src: url('../assets/fonts/VickySymbols.eot');
  src: url('../assets/fonts/VickySymbols.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/VickySymbols.woff') format('woff'),
    url('../assets/fonts/VickySymbols.ttf') format('truetype'),
    url('../assets/fonts/VickySymbols.svg#Glyphter') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cochin';
  src: url('../assets/fonts/CochinLTStd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HandWriting';
  src: url('../assets/fonts/HandWriting.otf') format('otf'),
    url('../assets/fonts/HandWriting.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SimpleFiligree';
  src: url('../assets/fonts/SimpleFiligree.eot');
  src: url('../assets/fonts/SimpleFiligree.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SimpleFiligree.woff') format('woff'),
    url('../assets/fonts/SimpleFiligree.ttf') format('truetype'),
    url('../assets/fonts/SimpleFiligree.svg#SimpleFiligree') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SpringVibes';
  src: url('../assets/fonts/SpringVibes.eot');
  src: url('../assets/fonts/SpringVibes.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SpringVibes.woff') format('woff'),
    url('../assets/fonts/SpringVibes.ttf') format('truetype'),
    url('../assets/fonts/SpringVibes.svg#SpringVibes') format('svg');
  font-weight: normal;
  font-style: normal;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(20deg);
  }
  95% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.ScrAppBook .app-installation-container {
  h2,
  p,
  ul,
  .app-installation-title,
  ol {
    color: black;
  }
}

.display-flex {
  display: flex;
}

.dark-background {
  background-color: $darkBackground;

  body {
    background-color: $darkBackground;
  }
}

.w-unset {
  width: unset !important;
}

.liststyle-none {
  list-style: none;
}

.max-w-90-p {
  max-width: 90%;
}

.w-90-p {
  width: 90%;
}

@media screen and (min-width: 992px) {
  .w-auto-lg {
    width: auto !important;
  }
}

.ScrAppBook.white-background {
  background-color: white !important;
}

.modal-single-image-wrapper,
.modal-body p {
  -webkit-user-select: unset !important;
  -webkit-touch-callout: unset !important;

  img {
    -webkit-user-select: unset !important;
    -webkit-touch-callout: unset !important;
  }
}

// .modal {
//   overflow: hidden;
// }

.modal-open {
  // overflow: auto;
  overflow: hidden;
  padding-right: 0px !important;
}

.modal-open[style] {
  padding-right: 0px !important;
}

.ScrAppBook {
  .slide.center-top-background-image {
    background-position: center top !important;
  }

  -webkit-user-select: none;
  -webkit-touch-callout: none;

  .gallery-message {
    margin-top: 150px;
    text-align: center;
  }

  .gallery-text-wrapper {
    margin-top: 5rem;
    margin-bottom: 5rem;
    text-align: center;
    padding: 10px;

    p {
      color: white;
      margin-bottom: 0;
    }
  }

  .video-wrapper {
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 3rem;
    padding: 0 0.7rem;

    video {
      /*min-height: 400px;*/
      min-width: 100%;
      max-width: 100%;
    }
  }

  .embed-video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    height: auto;
    margin-bottom: 3rem;
    margin-top: 3rem;

    div {
      overflow: unset !important;
      position: unset !important;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  @media screen and (max-width: 409px) {
    .classic-menu {
      font-size: 10px;
    }
  }

  @media screen and (min-width: 410px) {
    .classic-menu {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 991px) {
    .embed-video-wrapper,
    .video-wrapper {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: 576px) {
    .embed-video-wrapper,
    .video-wrapper {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .gallery-seperator {
    display: none;
  }

  .no-likes-container {
    text-align: center;
  }

  .gallery-section {
    margin-top: 5px;
    padding-bottom: 10px !important;
  }

  .tracking-hint {
    .site-button {
      background-color: black !important;
      color: white !important;
    }
  }

  .thumbnail-liked.fa-heart-red {
    top: 5px;
    left: 5px;
    font-size: 15px;
    position: absolute;
    color: red;
  }

  .thumbnail-image-like-fade-wrapper {
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;

    .fa-heart-red {
      top: 50%;
      font-size: 50px;
      position: absolute;
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
      color: red;
    }
  }

  @media screen and (min-width: 992px) {
    .thumbnail-image-like-fade-wrapper.no-mobile-device {
      opacity: 1;
      background-color: rgba(25, 25, 25, 0.4);
    }
  }

  .isApp {
    .selection-bar {
      bottom: $bottomNavRowHeight;
    }

    .fixed-gift-button,
    .fixed-shop-button {
      left: 50%;
      transform: translateX(-50%);
      bottom: 40px;
    }

    .imprint-container {
      padding-bottom: 70px;
    }

    .fadeIn {
      animation-name: none !important;
    }

    .animated {
      animation-duration: unset !important;
    }

    .slider {
      height: calc(100% - #{$bottomNavRowHeight});
    }

    // .home-view {
    //   overflow-x: hidden;
    // }

    .photographer-view,
    .photographer {
      height: 100%;
    }

    .share-view,
    .photographer {
      .container {
        padding-bottom: $bottomNavRowHeightWithMargin;
      }
    }

    .photographer-view {
      display: unset;
    }

    .photographer-footer-bio {
      padding-bottom: 80px;
    }

    .isApp-pb-20 {
      padding-bottom: 20px !important;
    }

    #gallery-area .gallery-section .isApp-pb-20 {
      padding-bottom: 0;
    }

    .bottom-nav-row {
      display: flex !important;
    }
  }

  -webkit-overflow-scrolling: touch;

  .modal .imprint-container {
    display: none !important;
  }

  footer .copyright {
    padding: 10px 0;

    a {
      margin: 0 10px;
    }
  }

  .masonry-gallery-small {
    margin-left: 0.7%;
    margin-right: 0.7%;
    margin-top: 0.7%;
    outline: none;
  }

  @media screen and (min-width: 961px) {
    $masonry-gutter-space: 0.6%;

    .masonry-gallery-small {
      margin-left: $masonry-gutter-space;
      margin-right: $masonry-gutter-space;
      margin-top: $masonry-gutter-space;
    }
  }

  @media screen and (min-width: 1440px) {
    $masonry-gutter-space: 0.25%;

    .masonry-gallery-small {
      margin-left: $masonry-gutter-space;
      margin-right: $masonry-gutter-space;
      margin-top: $masonry-gutter-space;
    }
  }

  /* MIDDLE */
  .masonry-gallery-middle {
    margin-left: 1.9%;
    margin-right: 1.9%;
    margin-top: 1.9%;
  }

  .masonry-gallery-large {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 4%;
    outline: none;
  }

  .fixed-sharing-button-old-scrappbook button {
    background-color: white;
  }

  .fixed-sharing-button-old-scrappbook,
  .fixed-sharing-button,
  .fixed-gift-button,
  .fixed-shop-button {
    position: fixed;
    bottom: 30px;
    z-index: 150 !important;
    width: 50px;
    height: auto;

    button,
    a {
      width: 50px !important;
      height: 50px !important;
      outline: 0;
      border: 0;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .fixed-sharing-button-old-scrappbook,
  .fixed-sharing-button {
    right: 30px;
  }

  .fixed-gift-button,
  .fixed-shop-button {
    left: 30px;

    i {
      font-size: 25px;
      display: inline-block;
      animation: wiggle 2.5s infinite;
    }
  }

  .sb-gallery-image-hover {
    display: none;
  }

  @media screen and (min-width: 992px) {
    .fixed-sharing-button,
    .fixed-gift-button,
    .fixed-shop-button {
      display: none !important;
    }

    .fixed-shop-button.classicTemplate {
      display: block !important;
    }

    .sb-gallery-image-hover {
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      opacity: 0;

      .hover-action-container {
        bottom: 10px;
        left: 10px;
        font-size: 20px;
        position: absolute;
        width: 100%;
        display: flex;

        i {
          margin: 0 5px 0 5px;
          cursor: pointer;

          .comment-badge {
            margin-left: -10px;
          }
        }

        i.liked-image {
          color: #c50520 !important;

          &:hover {
            color: #ffb2b2 !important;
          }
        }

        i.fa:hover,
        .gallery-eye-icon:hover {
          color: #aeaeae;
        }
      }
    }

    .sb-gallery-image-hover:hover {
      opacity: 1;
      background: linear-gradient(0deg, rgba(25, 25, 25, 0.8), rgba(0, 0, 0, 0) 40%);

      i,
      .gallery-eye-icon {
        color: white;
      }
    }
  }

  .gallery-card-paddings {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.8rem;
  }

  .gallery-card-container {
    .gallery-card {
      height: 300px;

      padding-left: 0.8rem;
      padding-right: 0.8rem;
      padding-top: 0.8rem;

      h2.gallery-card-title {
        font-weight: 400 !important;
      }

      .gallery-card-title {
        position: absolute;
        bottom: 25%;
        padding: 10px;
        z-index: 99;
        width: 100%;
        text-align: center;
      }

      .gallery-card-footer {
        position: absolute;
        bottom: 10px;
        z-index: 99;
        padding-left: 15px;

        i {
          font-size: 1.3rem;
          width: 3.5rem;
          margin-top: 15px;
        }
      }

      .gallery-card-image {
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        border-radius: 10px;
        position: relative;
        background-position: center top;

        .transparent-overlay {
          position: relative;
        }

        .sharing {
          .sharing-content {
            z-index: 99;
            width: 100%;
            position: absolute;
          }

          .gallery-sharing-title {
            width: 100%;
            padding: 15px;
            z-index: 99;
          }

          .gallery-sharing-links {
            color: white;
            padding-right: 15px;
            padding-left: 15px;
            padding-bottom: 15px;

            li {
              font-size: 1rem;
              margin-bottom: 0.25rem;
            }
          }
        }
      }
    }
  }

  .nav-bottom-sticky {
    .bottom-nav-row {
      height: $bottomNavRowHeight;
      display: none;
    }

    .nav-icon {
      font-size: 30px;

      &.disabled {
        opacity: 0.3;
      }
    }
  }

  .bottom-5 {
    bottom: 5px;
  }

  .bottom-10 {
    bottom: 10px;
  }

  .home-view,
  .gallery-view {
    width: 100%;
  }

  .photographer-view.d-block .contact-profile-image {
    height: 25vh;
  }

  .photographer-view {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    h2.footer-title {
      margin-bottom: 1.5em;
    }

    .contact-profile-image {
      height: 40vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
      position: relative;

      h3 {
        text-align: center;
        padding-top: 10%;
      }

      .rounded-logo {
        height: 150px;
        width: 150px;
        border-radius: 50%;
        position: absolute;
        bottom: -75px;
        left: 50%;
        transform: translateX(-50%);

        img {
          position: relative;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
          max-width: 100%;
          height: auto;
          padding: 15%;
        }
      }

      @media screen and (min-width: 576px) {
        .rounded-logo {
          height: 200px;
          width: 200px;
          bottom: -100px;
        }
      }
    }

    ul {
      margin-top: 90px;
      list-style: none;
      padding: 0 15px 20px 0;

      li {
        margin-bottom: 5px;

        span,
        a {
          font-size: 15px;
          text-decoration: none;
        }

        i,
        svg {
          font-size: 1.5em;
          position: relative;
          top: 2px;
          margin-right: 20px;
        }

        i.fa-envelope {
          font-size: 1.4em;
        }
      }

      li:first-child {
        margin-bottom: 15px;
      }

      li:last-child {
        margin-bottom: 0px;
      }
    }

    @media screen and (min-width: 576px) {
      ul {
        margin-top: 130px;
      }
    }

    .social-icons {
      display: flex;
      margin-bottom: 20px;
      float: left;

      a:hover {
        text-decoration: none;
      }

      a {
        margin-right: 10px;

        div {
          background-color: #5d5d5d;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          color: white;
          display: flex;
        }

        i {
          font-size: 25px;
          margin: auto;
        }
      }

      a:first-child {
        margin-left: auto;
      }

      a:last-child {
        margin-right: auto;
      }
    }

    .social-icons {
      a div:hover {
        background-color: white;
      }
    }
  }

  .share-view {
    .transparent-overlay {
      position: fixed !important;
    }

    position: absolute;
    width: 100%;
    height: 100%;
    margin-bottom: $bottomNavRowHeight;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 60%;
    overflow-y: auto;
    color: var(--modal-font-color);

    h3 {
      position: relative;
      z-index: 99;
      margin-top: 10%;
      text-align: center;
      margin-bottom: 17.5%;
      padding-bottom: 0;
    }

    h4 {
      z-index: 99;
      position: relative;
    }

    .rounded-background {
      background-color: rgba(17, 17, 17, 0.8);
      border-radius: 15px;
    }
  }

  .share-ul {
    position: relative;
    list-style: none;
    padding: 20px 15px 20px 15px;

    a {
      color: var(--modal-font-color) !important;
    }

    li {
      margin-bottom: 15px;

      span {
        font-size: 20px;
      }

      select::-ms-expand {
        display: none;
      }

      select,
      .dropdown-container {
        font-size: 20px;
        width: 100%;
        appearance: none;
        border: none;
        background: none;
        border-bottom: solid 1px grey;
        border-radius: 0;
      }

      .select-wrapper {
        position: relative;
        width: calc(100% - 60px);
        display: inline-block;
      }

      .select-wrapper:after,
      .dropdown-heading-value:after {
        font-family: FontAwesome;
        position: absolute;
        right: 15px;
        pointer-events: none;
        content: '\f0d7';
        font-size: 25px;
      }

      .select-wrapper.multi-select:after {
        display: none;
      }
    }

    li:last-child {
      margin-bottom: 0px;
    }

    i {
      font-size: 25px;
      margin-right: 30px;
      position: relative;
      top: 2px;
    }
  }

  .app-installation-images-row {
    text-align: center;

    img {
      height: 700px;
    }
  }

  .slider {
    position: relative;
    width: 100vw;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;

    .slider-wrapper {
      position: relative;
      height: 100%;
      width: 100%;
    }

    .slide {
      display: inline-block;
      height: 100%;
      width: 100%;
    }

    .slide-content {
      position: absolute;
      bottom: 25vh;
      transform: translateX(-50%);
      z-index: 99;
      left: 50%;
      width: 100%;

      .photographer-logo {
        display: none;
      }

      .slide-title {
        color: white;
        font-size: 2.5rem;
        text-transform: uppercase;
        white-space: normal;
        word-break: break-word;
      }

      @media screen and (min-width: 992px) {
        .slide-title {
          font-size: 3.5rem;
        }
      }

      .slide-sub-title {
        color: white;
        margin-top: 0.5em;
        font-weight: 400 !important;
      }

      @media screen and (max-width: 575px) {
        .slide-sub-title {
          font-size: 1.2rem !important;
        }
      }
    }

    .slide-cta {
      position: absolute;
      bottom: 6vh;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;

      .cta-text {
        margin-bottom: 20px;
        text-transform: uppercase;
        font-size: 0.9rem;
      }

      i {
        font-size: 1.7rem;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .slider {
      .photographer-logo {
        background-color: white;
        display: block;
        height: 120px;
        width: 120px;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 99;

        img {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          padding: 15%;
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  .footer-logo {
    height: 250px;
    width: 250px;
    border-radius: 50%;

    img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      max-width: 100%;
      max-height: 100%;
      height: auto;
      padding: 15%;
    }
  }

  .transparent-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .photographer-footer-bio {
    margin-top: 1.4rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;

    .flex-image {
      flex: 0 0 75px;

      .image {
        background: no-repeat 50%;
        background-size: cover;
        border-radius: 50%;
        min-height: 75px;
        width: 75px;
        display: inline-block;
        background-color: white;
      }

      .logo-image {
        border-radius: 50%;
        height: 75px;
        width: 75px;
        display: inline-block;
        background-color: white;

        .logo-image-img {
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
          max-width: 100%;
          height: auto;
          padding: 10px;
          position: relative;
        }
      }
    }

    .text {
      margin: auto 0 auto 0;

      button {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        text-transform: uppercase;
        margin-top: 10px;
        border: 0;
      }
    }
  }

  .nav-top-sticky {
    position: fixed;
    font-size: 20px;
    z-index: 999;
    background-color: $darkPrimary;
    width: 100%;

    i {
      padding: 10px 25px 10px 10px;
    }

    .nav-top-sticky-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 5px;
      white-space: pre;

      width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .selections-view {
    width: 100%;
    margin-bottom: $bottomNavRowHeight;

    h3 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .like-view {
    width: 100%;
    margin-bottom: $bottomNavRowHeight;

    h3 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .nav-desktop {
    z-index: 99;

    .nav-actions {
      .actions {
        padding: 5px 35px 5px 15px;
        display: flex;
        align-items: center;

        i {
          margin-left: 20px;
          font-size: 20px;
          cursor: pointer;
        }

        i:hover {
          color: #aeaeae;
        }
      }
    }

    .gallery-selection {
      padding: 5px 0 5px 0;
      overflow: auto;
      white-space: nowrap;

      .gallery-button {
        padding: 5px 15px 5px 15px;
        background-color: transparent;
        color: white;
        border: solid 2px;
        border-radius: 5px;
        margin-right: 20px;
        font-size: 15px;
        text-decoration: none;
      }

      .gallery-button:hover {
        color: #aeaeae;
      }

      .gallery-button:first-child {
        margin-left: 20px;
      }
    }

    .gallery-selection-overflow {
      top: 5px;
      padding: 0 10px 0 10px;
      font-size: 20px;
      position: absolute;
      cursor: pointer;
      z-index: 99;
    }

    .gallery-selection-overflow.right {
      right: -15px;
    }

    .gallery-selection-overflow.left {
      left: 0;
    }
  }

  .nav-desktop.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  &.pswp-open .selection-bar {
    position: fixed !important;
    bottom: 0 !important;
    padding-bottom: 15px !important;
    top: unset !important;
  }

  .selection-bar {
    .favorite-preview-overflow {
      top: 12px;
      padding: 0 10px 0 10px;
      font-size: 20px;
      position: absolute;
      cursor: pointer;
      z-index: 99;
    }

    .favorite-preview-overflow.right {
      right: -15px;
    }

    .favorite-preview-overflow.left {
      left: -5px;
    }
  }

  .sb-btn-secondary:disabled,
  .sb-btn:disabled {
    cursor: not-allowed;
  }

  .sb-btn,
  .sb-btn-secondary {
    padding: 10px 20px 10px 20px;
    font-size: 17px;
    height: 50px;
    border-radius: 10px;
    border: solid 4px;
    text-align: left;
    cursor: pointer;
  }

  .sb-indicator {
    background-color: var(--hover-color);
  }

  .sb-btn-icon {
    display: flex;
    align-items: center;

    i,
    svg {
      width: 20px;
      margin-right: 10px;
    }
  }

  .sb-select {
    position: relative;
    select {
      font-size: 20px;
      width: 100%;
      appearance: none;
      border: none;
      border-bottom-color: currentcolor;
      border-bottom-style: none;
      border-bottom-width: medium;
      background: none;
      border-bottom: solid 1px grey;
    }
  }

  .sb-select:after {
    font-family: FontAwesome;
    position: absolute;
    right: 0px;
    pointer-events: none;
    content: '\F0D7';
    font-size: 25px;
  }

  .download-selection-modal,
  .modal-single-image {
    .modal-header {
      border-bottom: 0;
      background-color: white;
      color: #23282c;

      .modal-title {
        font-weight: 800 !important;
        word-wrap: break-word !important;
        margin-left: auto;
        margin-right: auto;
      }

      button {
        margin-left: 0 !important;
      }
    }

    .modal-content {
      button:not(.close):not(.sb-btn) {
        text-align: center;
        padding: 10px 20px 10px 20px;
        font-size: 20px;
        border-radius: 10px;
        border: solid 4px;
        background-color: white;
        color: black;
        border-color: black;
      }
    }
  }

  .modal-lg .modal-header .modal-title {
    font-weight: 800 !important;
    font-size: 1.7rem !important;
    word-wrap: break-word !important;
  }

  .app-installation-container {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    h2 {
      font-family: 'Roboto Slab', serif;
      margin-top: 15px;
      font-size: 1.9rem !important;
      font-weight: 400 !important;
    }

    .app-installation-benefits,
    .app-installation-steps {
      text-align: left;
      font-size: 20px;
      font-weight: 300;

      li {
        margin-bottom: 5px;
      }
    }

    .app-installation-steps {
      padding-left: 30px;
    }

    .app-installation-benefits {
      list-style-type: none;
      padding-left: 20px;

      li:before {
        font-family: 'FontAwesome';
        content: '\F058';
        margin: 0 5px 0 -20px;
      }
    }

    .app-installation-button {
      font-family: 'Roboto', sans-serif;
      text-align: center;
      padding: 10px 20px 10px 20px;
      font-size: 20px;
      border-radius: 10px;
      border: solid 4px;
      background-color: black;
      color: white;
      border-color: black;
    }
  }

  .download-pin-modal {
    font-family: 'Roboto', sans-serif;

    .modal-header {
      background-color: white;
      color: black;
      border-bottom: 0;

      .modal-title {
        font-family: 'Roboto Slab', serif;
        margin-left: auto;
        margin-right: auto;
        font-weight: 800 !important;
        font-size: 1.7rem !important;
        word-wrap: break-word !important;
      }

      button {
        margin-left: 0 !important;

        span {
          font-size: 2rem;
        }
      }
    }
  }

  &.light {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'Roboto Slab', serif;
    }

    button,
    .gallery-card-footer span {
      font-family: 'Roboto', sans-serif;
    }

    font-family: 'Roboto', sans-serif;

    .sb-btn {
      background-color: black;
      color: white;
      border-color: black;
    }

    .sb-btn:hover {
      background-color: white;
      color: black;
      border-color: black;
    }

    .sb-btn-no-hover {
      @extend .sb-btn;
      .no-hover-text {
        margin-left: 10px;
      }
      &:hover {
        background-color: black !important;
        color: white !important;
        border-color: black !important;
      }
    }

    .sb-btn-secondary {
      background-color: white;
      color: black;
      border-color: black;
    }

    .sb-btn-secondary:hover {
      background-color: black;
      color: white;
      border-color: black;
    }

    .modal-header {
      border-bottom: 0;

      .modal-title {
        margin-left: auto;
        margin-right: auto;
        font-weight: 800 !important;
      }

      button {
        margin-left: 0 !important;

        span {
          font-size: 2rem;
        }
      }
    }
  }

  &.dark {
    $stickyBarColor: rgba(25, 25, 25, 0.9);
    $font: 'Roboto';
    $headingFont: 'Roboto Slab';
    --font: #{$font};
    --heading-font: #{$headingFont};
    --primary-color: #{$darkBackground};
    --secondary-color: white;
    --primary-color-alpha90: #{$stickyBarColor};
    --page-bg: #{$darkBackground};
    --primary-color-alpha70: rgba(25, 25, 25, 0.7);
    --primary-color-alpha40: rgba(25, 25, 25, 0.4);
    --primary-inactive-color: #888;
    --imprint-bg-color: var(--primary-color);

    .pswp__button--arrow--next {
      mask-image: url('../assets/images/icons/dark - arrow right.svg');
      -webkit-mask-image: url('../assets/images/icons/dark - arrow right.svg');
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: 50% 50%;
      -webkit-mask-position: 50% 50%;
      background-color: white !important;
    }

    .pswp__button--arrow--prev {
      mask-image: url('../assets/images/icons/dark - arrow left.svg');
      -webkit-mask-image: url('../assets/images/icons/dark - arrow left.svg');
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: 50% 50%;
      -webkit-mask-position: 50% 50%;
      background-color: white !important;
    }

    .photographer-info {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      color: var(--secondary-color);
    }

    .multi-select .gray {
      color: var(--secondary-color);
    }

    .select-wrapper {
      background: var(--primary-color);

      &:after {
        right: 23px;
        color: var(--secondary-color) !important;
      }

      border-radius: 10px;
      border: 1px solid var(--secondary-color);
    }

    .rmsc.multi-select .dropdown-container {
      border-bottom: none !important;

      .dropdown-heading-value:after {
        color: var(--secondary-color) !important;
      }

      .item-renderer {
        color: var(--primary-color) !important;
      }
    }

    .checkmark-wrapper {
      padding-top: 7px;
    }

    .modal-content .share-ul {
      .multi-select .gray {
        color: var(--secondary-color);
      }
    }

    .share-view ul li label div span {
      color: var(--primary-color) !important;
    }

    .share-view .transparent-overlay {
      z-index: 0;
    }
    .upselling-package-card {
      border-radius: 10px;
    }

    .selection-bar {
      padding-bottom: 15px;
      background-color: var(--primary-color);

      .favorite-counter {
        color: var(--secondary-color);
      }

      .fa-caret-left,
      .fa-caret-right {
        color: var(--secondary-color);
      }
    }

    .dark {
      font-family: var(--font), sans-serif;
      background-color: var(--page-bg);
      min-height: 100vh;
    }

    font-family: var(--font), sans-serif;
    background-color: var(--page-bg);
    min-height: 100vh;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .gallery-selection a {
      color: var(--secondary-color);
    }

    .sb-gallery-image-hover:hover {
      opacity: 1;
      background: linear-gradient(0deg, var(--primary-color-alpha40), rgba(0, 0, 0, 0) 40%);

      i,
      .gallery-eye-icon {
        color: var(--secondary-color);
        outline: none;
      }

      .gallery-eye-icon:hover {
        color: #aeaeae;
      }
    }

    .sb-btn {
      border-color: var(--secondary-color);
      background-color: var(--secondary-color);
      color: var(--primary-color);

      svg,
      svg g path {
        fill: var(--primary-color) !important;
        stroke: var(--primary-color) !important;
      }
    }

    .sb-btn:hover {
      background-color: var(--primary-color);
      color: var(--secondary-color);
      border-color: var(--secondary-color);

      div,
      svg,
      svg path {
        color: var(--secondary-color);
        border-color: var(--secondary-color);
        fill: var(--secondary-color) !important;
        stroke: var(--secondary-color) !important;
        border-bottom-color: transparent;
      }
    }

    .sb-btn-no-hover {
      @extend .sb-btn;
      .no-hover-text {
        margin-left: 10px;
      }
      &:hover {
        background-color: var(--secondary-color) !important;
        color: var(--primary-color) !important;

        svg {
          fill: var(--primary-color);
          stroke: var(--primary-color);
        }
      }
    }

    .sb-btn-dark {
      background-color: var(--primary-color);
      color: var(--secondary-color);
      border-color: var(--secondary-color);
      svg {
        fill: var(--secondary-color);
        stroke: var(--secondary-color);
      }
    }

    .footer-logo {
      background-color: var(--secondary-color);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--heading-font), serif;
    }

    button,
    .gallery-card-footer span {
      font-family: var(--font), sans-serif;
    }

    .imprint-container {
      background-color: var(--imprint-bg-color);
      color: var(--secondary-color);

      a {
        color: #aeaeae;
      }

      @media screen and (min-width: 992px) {
        footer {
          text-align: right !important;
        }
      }
    }

    .nav-top-sticky {
      background-color: var(--primary-color);
    }

    .nav-desktop {
      background-color: var(--primary-color-alpha90);
      color: var(--secondary-color);
    }

    .profile-dropdown-toggle:before {
      color: var(--primary-color);
    }

    .sb-select select,
    .select-wrapper select {
      border-bottom: none !important;
      color: var(--secondary-color);

      &:focus-visible {
        outline: none;
      }

      option {
        color: var(--primary-color);
      }
    }

    .select-wrapper {
      padding: 4px;
      .dropdown-container {
        border-bottom: solid 1px transparent !important;
      }
    }

    .modal-content {
      background: var(--primary-color);
      h3 {
        color: var(--secondary-color) !important;
      }

      /*.rmsc .gray {
        color: var(--primary-color) !important;
      }*/

      .share-ul {
        color: var(--primary-color) !important;
      }
    }

    .modal-header {
      border-bottom: 0;

      .modal-title {
        color: var(--secondary-color) !important;
        margin-left: auto;
        margin-right: auto;
        font-weight: 800 !important;
      }

      &.info-modal-title .modal-title {
        font-size: 1.3rem !important;
        text-transform: unset;
      }

      button {
        margin-left: 0 !important;

        span {
          font-size: 2rem;
        }
      }
    }

    .photographer-modal {
      .photographer-view {
        ul {
          li {
            pointer-events: auto;
          }
        }
      }
      .modal-body {
        background-color: var(--primary-color);
        color: var(--secondary-color);
      }
    }

    .fixed-sharing-button,
    .fixed-gift-button,
    .fixed-shop-button {
      a,
      button {
        background-color: var(--secondary-color);
        color: var(--primary-color);
      }
    }

    .photographer-view.d-lg-block {
      .social-icons {
        float: left;
      }
    }

    .social-icons {
      i {
        color: var(--secondary-color);
      }

      a div {
        background-color: var(--primary-color-alpha70);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .photographer-view {
      .contact-profile-image {
        .rounded-logo {
          background-color: var(--secondary-color);
        }
      }

      li a,
      h2 {
        color: var(--secondary-color);
      }

      a:hover {
        color: #aeaeae;
      }

      .social-icons {
        float: unset;
      }
    }

    .sb-select,
    .select-wrapper,
    .dropdown-heading-value {
      select {
        color: var(--secondary-color) !important;
        border-bottom-color: var(--secondary-color) !important;
      }

      &:after {
        color: var(--secondary-color) !important;
      }
    }

    .select-wrapper {
      padding: 4px;
    }

    .share-view {
      .rounded-background {
        ul {
          select {
            color: var(--secondary-color);
          }

          .select-wrapper:after,
          .dropdown-heading-value:after {
            color: var(--secondary-color);
          }
        }
      }

      .share-loader {
        .spinner-grow {
          color: #f0f3f5 !important;
        }

        p {
          z-index: 99;
          color: var(--secondary-color);
        }
      }
    }

    .share-ul {
      a {
        color: var(--secondary-color);
      }

      color: var(--secondary-color);
    }

    .share-ul a:hover {
      text-decoration: none !important;
    }

    .share-view,
    .like-view,
    .selections-view,
    .photographer-view {
      h3,
      h4,
      ul {
        color: var(--secondary-color);
      }
    }

    .no-likes-container {
      color: var(--secondary-color);
      text-align: center;
    }

    .nav-top-sticky {
      color: var(--secondary-color);
    }

    .photographer-footer-bio {
      h3 {
        color: var(--secondary-color);
      }

      .text {
        color: var(--secondary-color);

        button {
          background-color: var(--secondary-color);
          color: var(--primary-color);
        }
      }
    }

    .transparent-overlay {
      background-color: var(--primary-color-alpha40);
    }

    .transparent-overlay.overlay-50 {
      background-color: var(--primary-color-alpha70);
    }

    .gallery-card-image {
      .sharing {
        .gallery-sharing-title {
          width: 100%;
          padding: 15px;
          color: var(--secondary-color);
        }
      }
    }

    .slider .slide-cta {
      i,
      .cta-text {
        color: var(--secondary-color);
      }
    }

    .gallery-card-title {
      color: var(--secondary-color);
    }

    .gallery-card-footer {
      i {
        color: var(--secondary-color);
        cursor: pointer;
      }

      i:hover {
        color: #aeaeae;
      }
    }

    .nav-bottom-sticky {
      position: fixed;
      z-index: 100;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      background-color: var(--primary-color);
      line-height: 25px;
      color: var(--primary-inactive-color);

      .nav-icon.active {
        color: var(--secondary-color);
      }

      .nav-column.active {
        border-top: solid 3px var(--secondary-color);
        width: 80%;
        margin: auto;
      }
    }
  }

  &.VickyBaumannBoho {
    $vickyBeige: #946a4d;
    $vickyBeigeHover: #865e42;
    $stickyBarColor: white;
    $font: 'Lato';
    $headingFont: 'Playfair Display';
    --font: #{$font};
    --heading-font: #{$headingFont};
    --primary-color: #{$vickyBeige};
    --secondary-color: #{$stickyBarColor};
    --decorations-color: #{$vickyBeige};

    .sb-btn-secondary {
      background-color: var(--secondary-color);
      color: var(--primary-color);
      border-color: var(--primary-color);
      border-radius: 0;
    }

    button.close {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      text-shadow: 0 1px 0 #fff;
      background-color: transparent;
      border: 0;
    }

    .photographer-info {
      font-size: 16px;
      text-transform: initial;
      margin-top: 30px;
      letter-spacing: initial;
      font-weight: 300;
    }

    .multi-select .gray {
      color: var(--primary-color);
    }

    .gallery-text-wrapper p {
      color: black;
    }

    .checkmark-wrapper {
      padding-top: 8px;
    }

    &.disable-decorations {
      .social-icons a div:before {
        content: '' !important;
        display: none;
      }

      .gallery-seperator {
        display: none !important;
      }

      .share-view-title-wrapper img {
        display: none !important;
      }
    }

    .input-element {
      background: var(--secondary-color) !important;
    }

    .selection-bar {
      background-color: var(--secondary-color);

      &.sticky {
        padding-bottom: 15px;
      }
    }

    .VickyBaumannBoho {
      font-family: var(--font), sans-serif;
      background-color: var(--secondary-color);
      min-height: 100vh;
    }

    font-family: var(--font), sans-serif;
    background-color: var(--secondary-color);
    min-height: 100vh;

    .sb-btn,
    .download-selection-modal .modal-content a {
      border-color: var(--primary-color);
      background-color: var(--primary-color);
      color: var(--secondary-color);
      border-radius: 0;

      svg {
        fill: var(--secondary-color);
        stroke: var(--secondary-color);
      }
    }

    .sb-btn:hover,
    .download-selection-modal .modal-content a:hover {
      border-color: var(--primary-color);
      background-color: var(--secondary-color);
      color: var(--primary-color);

      div,
      svg,
      svg path {
        color: var(--primary-color);
        border-color: var(--primary-color);
        fill: var(--primary-color);
        stroke: var(--primary-color) !important;
        border-bottom-color: transparent;
      }
    }

    .sb-btn-no-hover {
      @extend .sb-btn;
      .no-hover-text {
        margin-left: 10px;
      }
      background-color: var(--primary-color) !important;
      color: var(--secondary-color) !important;
      border-radius: 0 !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--heading-font), serif;
    }

    button,
    .gallery-card-footer span {
      font-family: var(--font), sans-serif;
    }

    .fixed-sharing-button,
    .fixed-gift-button,
    .fixed-shop-button {
      .btn-circle {
        background-color: var(--primary-color);

        .fa {
          color: var(--secondary-color);
        }
      }
    }

    .slider-wrapper {
      transform: unset !important;
      transition: unset !important;

      .slide {
        position: absolute;
        left: 0;
        top: 0;
        transition: opacity 1s ease-in-out;
      }
    }

    .pswp__button--fs {
      background-image: url('../assets/templates/vickybaumannboho/full-screen.png') !important;
      background-size: unset !important;
      background-position: center !important;
    }

    .pswp__button--zoom {
      background-image: url('../assets/templates/vickybaumannboho/search.png') !important;
      background-size: unset !important;
      background-position: center !important;
    }

    .pswp__button--close {
      background-image: url('../assets/templates/vickybaumannboho/close.png') !important;
      background-size: unset !important;
      background-position: center !important;
    }

    .pswp__button--arrow--next {
      mask-image: url('../assets/images/icons/vicky boho - arrow right.svg');
      -webkit-mask-image: url('../assets/images/icons/vicky boho - arrow right.svg');
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: 50% 50%;
      -webkit-mask-position: 50% 50%;
      background-color: var(--primary-color) !important;
      background-image: none !important;
    }

    .pswp__button--arrow--prev {
      mask-image: url('../assets/images/icons/vicky boho - arrow left.svg');
      -webkit-mask-image: url('../assets/images/icons/vicky boho - arrow left.svg');
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: 50% 50%;
      -webkit-mask-position: 50% 50%;
      background-color: var(--primary-color) !important;
      background-image: none !important;
    }

    .fixed-sharing-button .fa-share {
      font-size: 20px;
    }

    .imprint-container {
      background-color: var(--secondary-color);
      margin-top: 1.1em;
      padding-bottom: 100px;
      color: black;
    }

    .sb-gallery-image {
      max-height: 100%;
    }

    .comment-badge {
      background-color: var(--primary-color);
    }

    .photoswipe-like-button-liked .photoswipe-custom-button-like {
      color: #ff4c4c;
    }

    .sb-gallery-image-hover .hover-action-container {
      i.fa:hover,
      .gallery-eye-icon:hover {
        color: #d5b097;
      }

      i.liked-image {
        color: #c50520;
      }

      i {
        color: var(--secondary-color);
      }

      .gallery-eye-icon {
        opacity: 0.9;
      }
    }

    .fa-twitter:before {
      content: '\004d';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .icon-close:before {
      content: '\0041';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .vicky-symbols-arrow:before {
      content: '\0042';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-comment-o:before,
    .fa-comment:before {
      content: '\0043' !important;
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-copy:before {
      content: '\0044';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .icon-cloud-download:before,
    .fa-download:before {
      content: '\0045';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-envelope:before {
      content: '\0046';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-facebook-square:before,
    .fa-facebook:before {
      content: '\0047';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-home:before,
    .fa-image:before {
      content: '\0048';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-heart-o:before,
    .fa-heart:before {
      content: '\0049';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-search-plus:before {
      content: '\004a';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-pinterest:before {
      content: '\004b';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-share:before {
      content: '\004c';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-address-card:before {
      width: 28px;
      height: 20px;
      display: inline-block;
      content: '';
      -webkit-mask: url(../assets/images/photographer-vicky.svg) no-repeat 50% 50%;
      mask: url(../assets/images/photographer-vicky.svg) no-repeat 50% 50%;
      -webkit-mask-size: contain;
      mask-size: contain;
      background-color: rgb(255, 255, 255);
    }

    .profile-dropdown-toggle:before {
      content: '\004e';
      display: inline-block;
      color: black;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-whatsapp:before {
      content: '\004f';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-info:before {
      content: '\0050';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-gift:before {
      content: '\0051';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-youtube:before {
      content: '\0055';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-instagram:before {
      content: '\0052';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-check-circle:before {
      content: '\0054';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-circle:before {
      content: '\0053';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-chevron-left:before {
      content: '\0056';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .gallery-section {
      margin-top: 15px;
    }

    .nav-bottom-sticky {
      position: fixed;
      z-index: 100;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      background-color: var(--primary-color);
      line-height: 25px;
      color: var(--secondary-color);
    }

    .nav-top-sticky {
      position: fixed;
      font-size: 20px;
      z-index: 999;
      background-color: var(--primary-color);
      width: 100%;
      color: var(--secondary-color);

      .nav-top-sticky-title {
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-weight: 500;
        font-family: var(--heading-font), serif;
      }
    }

    .photographer-footer-bio {
      img {
        max-width: 220px;
      }
    }

    .share-ul a {
      color: var(--secondary-color);
    }

    .slider {
      .slide-content {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--secondary-color);
          filter: opacity(80%);
          z-index: -1;
        }

        &.disable-decorations:before {
          top: 0%;
        }

        padding: 15px 0 15px 0;
        bottom: 5vh;

        .slide-title,
        .slide-sub-title {
          color: var(--primary-color);
        }

        .slide-title {
          color: var(--primary-color);
          font-size: 1.9rem;
          text-transform: uppercase;
          white-space: normal;
          font-weight: 600;
          letter-spacing: 0.1em;
        }

        .slide-sub-title {
          font-size: 0.9rem !important;
          text-transform: uppercase;
          font-weight: 600 !important;
          letter-spacing: 0.2em;
          margin: 1.5em 0;
        }
      }

      @media screen and (max-width: 991px) {
        .slide-content {
          bottom: 14vh;
        }
      }

      .title-image {
        margin-bottom: 15px;
        height: 75px;
      }

      .slide-cta {
        display: none;
      }

      .photographer-logo {
        background-color: unset !important;
        border-radius: unset !important;
        height: auto !important;

        display: block;
        max-width: 220px;
        width: unset;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: 25px;
        z-index: 99;

        img {
          max-width: 100%;
          height: auto;
          transform: unset !important;
          padding: unset !important;
          top: unset !important;
        }
      }
    }

    .gallery-card-container {
      .gallery-card {
        .gallery-card-image {
          border-radius: 0;

          .sharing .gallery-sharing-title {
            color: var(--secondary-color) f;
            text-transform: uppercase;
            letter-spacing: 0.1em;
          }

          .transparent-overlay {
            background-color: rgba(0, 0, 0, 0.5);
          }
        }

        .gallery-card-footer {
          color: var(--secondary-color);

          span {
            bottom: 5px;
            position: relative;
          }

          i {
            width: unset !important;
          }
        }

        .gallery-card-title {
          color: var(--secondary-color);
          width: 90%;
          transform: translateX(5%);
          padding: 25px 10px 25px 10px;
          border: solid 2px;
          text-transform: uppercase;
          letter-spacing: 0.2em;
        }
      }
    }

    .modal {
      overflow-y: auto;

      .modal-header {
        border-bottom: 0;
        color: var(--primary-color);

        button {
          margin-left: 0 !important;
        }

        .modal-title {
          text-transform: uppercase;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .info-modal-title .modal-title {
        font-size: 1.3rem !important;
        text-transform: unset;
      }

      .modal-content {
        border-radius: 0 !important;
      }

      .modal-header {
        color: var(--primary-color);
      }
    }

    .like-view h3,
    .selections-view h3 {
      color: var(--primary-color);
      text-transform: uppercase;
    }

    .share-view {
      .transparent-overlay {
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute !important;
      }

      .container {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
      }

      .rounded-background {
        background-color: rgba(255, 255, 255, 0.85);
        border-radius: 0;
      }

      .rounded-background:first-child {
        margin-top: 3rem;
      }

      .share-ul {
        li {
          span,
          select {
            color: var(--primary-color);
          }
        }
      }
    }

    .share-loader {
      color: var(--primary-color);
    }

    .share-view-title-wrapper {
      text-align: center;
      padding: 5px;
      text-transform: uppercase;

      img {
        height: 50px;
        margin-bottom: 5px;
      }

      h3 {
        color: var(--primary-color);
      }
    }

    .photographer-view {
      .contact-profile-image .rounded-logo {
        background-color: var(--secondary-color);
      }

      h3 {
        color: var(--primary-color);
      }

      ul {
        li {
          span:first-of-type {
            font-family: var(--heading-font), serif;
          }

          a {
            color: var(--modal-font-color);
            &:hover {
              color: var(--hover-color);
            }
          }
        }
      }

      .social-icons {
        margin-bottom: 0;

        a div {
          background-color: unset;
          color: black;
          border-radius: 0;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        i {
          z-index: 1;
        }

        $icon-backgrounds: (
          1: '../assets/templates/vickybaumannboho/SocialIcons-BG1.png',
          2: '../assets/templates/vickybaumannboho/SocialIcons-BG2.png',
          3: '../assets/templates/vickybaumannboho/SocialIcons-BG3.png',
          4: '../assets/templates/vickybaumannboho/SocialIcons-BG4.png',
          5: '../assets/templates/vickybaumannboho/SocialIcons-BG3.png'
        );

        @for $i from 1 through 5 {
          a:nth-child(#{$i}) div {
            &:before {
              content: '';
              position: absolute;
              width: 50px;
              height: 50px;
              display: block;
              mask-image: url(map-get($icon-backgrounds, $i));
              mask-size: 45px;
              mask-position: center;
              background-color: var(--decorations-color);
              -webkit-mask-repeat: no-repeat !important;
            }
          }
        }

        a div:hover {
          i {
            color: #d5b097 !important;
          }
          svg {
            fill: #d5b097;
            stroke: #d5b097;
          }
        }
      }
    }

    @media screen and (min-width: 1200px) {
      .gallery-selection {
        width: 800px !important;
      }
    }

    @media screen and (min-width: 1400px) {
      .gallery-selection {
        width: 950px !important;
      }
    }

    @media screen and (min-width: 1700px) {
      .gallery-selection {
        width: 1300px !important;
      }
    }

    .nav-desktop {
      &:before {
        content: '';
        position: relative;
        top: -15px;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: var(--primary-color);
        filter: opacity(80%);
      }
      color: var(--secondary-color) !important;
      height: 60px;

      a {
        color: var(--secondary-color) !important;
      }

      .col-12 {
        height: 35px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: auto;

        .gallery-selection-overflow.left {
          left: -15px;
        }
      }

      .gallery-selection .gallery-button:hover,
      .nav-actions .actions i:hover {
        color: #d5b097;
      }

      .gallery-selection {
        text-align: center;
        text-transform: uppercase;
        position: relative;
        width: 550px;

        .gallery-button {
          border: 1px solid;
          border-radius: 0;
        }
      }

      .nav-actions {
        position: absolute;
        right: 0px;
      }

      .fa-heart {
        top: 2px;
        position: relative;
        margin-right: 2px;
      }
    }

    .footer-logo {
      height: auto;
      margin-bottom: 20px;
      width: 250px;
      border-radius: unset;

      img {
        transform: unset;
        top: unset;
        padding: 15px;
      }
    }

    .modal-content {
      border-radius: 0 !important;
      border: 0px;
    }

    @media screen and (max-width: 991px) {
      .gallery-seperator {
        padding: 25px 0 25px;
        display: block;
        background-color: var(--primary-color);
        text-align: center;
        color: var(--secondary-color);

        span:first-child {
          font-size: 20px;
          text-transform: uppercase;
          margin-right: 100px;
          font-family: var(--heading-font), serif;
          letter-spacing: 0.2em;
        }

        span:last-child {
          font-size: 20px;
          text-transform: uppercase;
          margin-left: 100px;
          font-family: var(--heading-font), serif;
          letter-spacing: 0.2em;
        }

        span.special-font {
          font-family: 'signetonmedium';
          font-size: 25px;
          font-weight: 800;
        }
      }
    }

    @media screen and (min-width: 992px) {
      .slider {
        .slide-content {
          padding: 30px 0 30px 0;
        }
        .slide-attachment-fixed {
          background-attachment: fixed;
        }
      }

      .modal .modal-header .modal-title {
        font-weight: 600 !important;
        letter-spacing: 0.1em;
      }

      .sb-btn:hover,
      .download-selection-modal .modal-content a:hover {
        // border-color: $vickyBeigeHover;
        // background-color: $vickyBeigeHover;
        border-color: var(--primary-color);
        background-color: var(--primary-color);
        filter: brightness(0.8);
        color: var(--secondary-color);
        border-radius: 0;

        div,
        svg,
        svg path {
          color: var(--secondary-color);
          border-color: var(--secondary-color);
          fill: var(--secondary-color);
          stroke: var(--secondary-color) !important;
          border-bottom-color: transparent;
        }
      }
    }
  }

  &.SimpleFiligree {
    $lightGrey: #b7b7b7;
    $stickyBarColor: rgba(255, 255, 255, 0.8);
    $font: 'Josefin Sans';
    --font: #{$font};
    --primary-color: white;
    --secondary-color: black;
    --sticky-bar-color: #{$stickyBarColor};

    .photographer-info {
      transform: translate(105px, 110px);
      @media all and (max-width: 990px) {
        transform: translate(50px, 25px);
        max-width: calc(100% - 80px);
        white-space: initial;
      }
    }

    .modal-header {
      border-bottom: 1px solid var(--secondary-color);
    }

    .pswp__button {
      opacity: 0.75 !important;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1 !important;
      }
    }

    .sb-btn-secondary {
      background-color: var(--secondary-color);
      color: var(--primary-color);
      border-color: var(--primary-color);
      border-radius: 0;
    }

    .gallery-text-wrapper p {
      color: var(--secondary-color);
    }

    hr {
      border-top: 1px solid var(--secondary-color) !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .nav .nav-item a {
      color: var(--secondary-color);
    }

    .fixed-sharing-button-old-scrappbook button {
      background-color: var(--primary-color);
      color: var(--secondary-color);
    }

    .photographer-social-media i {
      color: var(--secondary-color);
    }

    .font-color {
      color: var(--secondary-color) !important;
    }

    color: var(--secondary-color) !important;

    .modal-content {
      border: none;
      border-radius: 0;
      background-color: var(--primary-color);
    }

    .no-likes-container {
      color: var(--secondary-color);
    }

    .modal-body,
    .share-ul {
      .fa-facebook-square:before {
        content: '\0045';
        display: inline-block;
        font-family: 'SimpleFiligree';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .select-wrapper:after,
      .dropdown-heading-value:after {
        color: var(--secondary-color);
      }

      select {
        border-bottom-color: var(--secondary-color);
      }
    }

    .checkmark-wrapper {
      padding-top: 6px;
    }

    .modal-header .modal-title {
      font-weight: 400 !important;
      text-transform: uppercase;
    }

    .info-modal-title .modal-title {
      font-size: 1.3rem !important;
      text-transform: unset;
    }

    .pswp__button--fs {
      background-image: url('../assets/templates/vickybaumannboho/full-screen.png') !important;
      background-size: unset !important;
      background-position: center !important;
    }

    .pswp__button--zoom {
      background-image: url('../assets/templates/vickybaumannboho/search.png') !important;
      background-size: unset !important;
      background-position: center !important;
    }

    .pswp__button--close {
      background-image: url('../assets/templates/vickybaumannboho/close.png') !important;
      background-size: unset !important;
      background-position: center !important;
    }

    .pswp__button--arrow--next {
      mask-image: url('../assets/images/icons/simple filigree - arrow right.svg');
      -webkit-mask-image: url('../assets/images/icons/simple filigree - arrow right.svg');
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: 50% 50%;
      -webkit-mask-position: 50% 50%;
      background-color: var(--secondary-color) !important;
      background-image: none !important;
    }

    .pswp__button--arrow--prev {
      mask-image: url('../assets/images/icons/simple filigree - arrow left.svg');
      -webkit-mask-image: url('../assets/images/icons/simple filigree - arrow left.svg');
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: 50% 50%;
      -webkit-mask-position: 50% 50%;
      background-color: var(--secondary-color) !important;
      background-image: none !important;
    }

    .fa-gift:before {
      content: '\0051';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-check-circle:before {
      content: '\004e';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-circle:before {
      content: '\004d';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-chevron-left:before {
      content: '\0056';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .icon-close:before {
      content: '\0042';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-copy:before {
      content: '\0043';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .icon-cloud-download:before,
    .fa-download:before {
      content: '\0044';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-envelope:before {
      content: '\0049';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-home:before {
      content: '\0046';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-heart-o:before,
    .fa-heart:before {
      content: '\0047';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      position: relative;
      top: 2px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-search-plus:before {
      content: '\0048';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-share:before {
      content: '\004a';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-address-card:before {
      width: 28px;
      display: inline-block;
      background-color: var(--secondary-color);
      height: 30px;
      display: inline-block;
      content: '';
      -webkit-mask: url(../assets/images/photographer-vicky.svg) no-repeat 50% 50%;
      mask: url(../assets/images/photographer-vicky.svg) no-repeat 50% 50%;
      -webkit-mask-size: contain;
      mask-size: contain;
    }

    .profile-dropdown-toggle {
      color: var(--secondary-color) !important;
    }

    .profile-dropdown-toggle:before {
      content: '\004b';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-whatsapp:before,
    .fa-comment-o:before,
    .fa-comment:before {
      content: '\004c' !important;
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-chevron-down:before {
      content: '\0041';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-image:before {
      content: '\0046';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      top: 3px;
      position: relative;
    }

    button.close:before {
      content: '\0042';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    button.close span {
      display: none;
    }

    .sb-container {
      margin-right: auto;
      margin-left: auto;
      width: 100%;

      max-width: 1200px;

      @media screen and (min-width: 1400px) {
        max-width: 1340px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 1540px;
      }

      @media screen and (min-width: 2000px) {
        max-width: 1840px;
      }

      @media screen and (min-width: 2400px) {
        max-width: 2240px;
      }
    }

    .SimpleFiligree {
      font-family: var(--font), sans-serif;
      letter-spacing: 0.1em;
      background-color: var(--primary-color);
      min-height: 100vh;
    }

    font-family: var(--font), sans-serif;
    background-color: var(--primary-color);
    min-height: 100vh;

    .sb-btn.sb-btn-icon .fa {
      top: 3px;
      position: relative;
    }

    .sb-btn i {
      text-transform: none !important;
    }

    .sb-btn,
    .download-selection-modal .modal-content a {
      border-color: var(--secondary-color);
      background-color: var(--primary-color);
      color: var(--secondary-color);
      border-radius: 0;
      border: 1px solid;
    }

    .sb-btn:hover,
    .download-selection-modal .modal-content a:hover {
      background-color: var(--secondary-color);
      color: var(--primary-color);
      border: 1px var(--secondary-color) solid;

      .fa-address-card:before {
        background-color: var(--primary-color) !important;
      }

      i {
        color: var(--primary-color) !important;
      }

      div,
      svg,
      svg path {
        color: var(--primary-color);
        border-color: var(--primary-color);
        fill: var(--primary-color);
        stroke: var(--primary-color) !important;
        border-bottom-color: transparent;
      }
    }

    .sb-btn-no-hover {
      @extend .sb-btn;
      .no-hover-text {
        height: 22px;
        margin-left: 10px;
      }
      &:hover {
        border-color: var(--secondary-color) !important;
        background-color: var(--primary-color) !important;
        color: var(--secondary-color) !important;
        border-radius: 0 !important;

        .fa-address-card:before {
          background-color: var(--secondary-color) !important;
        }

        i {
          color: var(--secondary-color) !important;
        }

        svg {
          fill: var(--secondary-color);
          stroke: var(--secondary-color);
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--font), serif;
    }

    button,
    .gallery-card-footer span {
      font-family: var(--font), sans-serif;
    }

    .gallery-card-footer span {
      bottom: 5px;
    }

    .fixed-sharing-button,
    .fixed-gift-button,
    .fixed-shop-button {
      .btn-circle {
        background-color: var(--primary-color);
        border: 0 solid;

        .fa {
          color: var(--secondary-color);
        }
      }
    }

    .transparent-overlay {
      display: none;
    }

    .share-ul i {
      top: 6px;
      position: relative;
      color: var(--secondary-color);
    }

    .share-description h4 {
      font-size: 1.15rem;
    }

    .isApp {
      .slider {
        height: auto;

        .slider-wrapper {
          height: calc(100vh - 110px);
        }
      }
    }

    .slider {
      height: auto;

      .photographer-logo {
        display: none;
      }

      .slider-wrapper {
        transform: unset !important;
        transition: unset !important;
        position: relative;
        height: calc(100vh - 50px);
        width: calc(100% - 80px);
        margin-top: 50px;

        .slide {
          position: absolute;
          left: 0;
          top: 0;
          transition: opacity 1s ease-in-out;
        }
      }

      .photographer-company {
        position: absolute;
        writing-mode: vertical-lr;
        right: -35px;
        transform: rotate(180deg);

        h3 {
          margin: 0;
          text-transform: uppercase !important;
          color: var(--secondary-color);
        }
      }

      .slide-content {
        position: relative;
        bottom: unset;
        left: unset;
        transform: none;
        display: block;
        text-align: left !important;
        margin-top: 30px;

        hr.d-lg-none {
          width: calc(100% - 50px);
          float: right;
          margin-top: 0 !important;
          margin-bottom: 15px !important;
        }

        .slide-title {
          color: var(--secondary-color);
          padding-left: 50px;
          font-size: 3.2rem;
        }

        .slide-sub-title {
          color: var(--secondary-color);
          padding-left: 50px;
        }
      }

      .slide-cta {
        position: relative;
        bottom: 0;
        left: 0;
        transform: unset;
        display: block;
        margin-top: 25px;
        margin-bottom: 20px;

        i {
          font-size: 2.7rem;
        }

        .fa-chevron-down {
          color: var(--secondary-color);
        }
      }
    }

    @media screen and (min-width: 992px) {
      .slider {
        height: 100%;

        .slider-wrapper {
          transform: unset !important;
          transition: unset !important;
          position: relative;
          height: 65%;
          width: 60%;
          left: 100px;
          top: 100px;

          .slide {
            position: absolute;
            left: 0;
            top: 0;
            transition: opacity 1s ease-in-out;
          }
        }

        .slide-content {
          right: 10%;
          top: 25vh;
          width: 40%;
          text-align: right !important;
          position: absolute;
          margin-top: 0;

          .slide-title,
          .slide-sub-title {
            padding: 0;
          }

          .slide-title {
            font-size: 2.7rem;
          }

          hr {
            border-top: 1px solid black;
          }
        }

        .photographer-company {
          left: -50px;
          bottom: 45px;
          right: unset;
        }

        .slide-cta {
          position: absolute;
          bottom: 25px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 99;
          margin: unset;
          display: none;
        }

        @media screen and (min-height: 666px) {
          .slide-cta {
            display: block;
          }
        }
      }
    }

    .selection-bar {
      background-color: var(--sticky-bar-color);
      padding-bottom: 15px;
    }

    .nav-desktop {
      background-color: var(--sticky-bar-color);

      .gallery-selection .gallery-button {
        color: var(--secondary-color);
        border-right: none;
        border-left: none;
        border-top: none;
        border-radius: 0;
        text-transform: uppercase;
      }
    }

    .nav-bottom-sticky {
      position: fixed;
      z-index: 100;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      background-color: var(--primary-color);
      line-height: 25px;
      color: var(--secondary-color);

      hr {
        margin: 0 15px 0 15px !important;
      }
    }

    .nav-top-sticky-wrapper {
      padding: 20px 0 20px;
      background-color: var(--primary-color);
      position: fixed;
      z-index: 100;
      width: 100%;
      top: 0;

      .nav-top-sticky {
        position: fixed;
        font-size: 20px;
        z-index: 999;
        background-color: var(--primary-color);
        width: 100%;
        color: var(--secondary-color);

        .nav-top-sticky-title {
          text-transform: uppercase;
          letter-spacing: 0.2em;
          font-weight: 500;
          font-family: var(--font), serif;
        }
      }
    }

    .gallery-card-container {
      .gallery-card {
        .gallery-card-image {
          border-radius: 0;

          .sharing {
            border: 1px solid $lightGrey;

            .gallery-sharing-title {
              color: var(--secondary-color);
              text-transform: uppercase;
              letter-spacing: 0.1em;
            }

            .transparent-overlay {
              display: none;
            }

            height: 100%;
            background-color: var(--primary-color);
            color: var(--secondary-color);

            .gallery-sharing-links {
              color: var(--secondary-color);

              .share-loader .spinner-grow {
                color: $lightGrey;
              }
            }
          }

          .transparent-overlay {
            background-color: rgba(0, 0, 0, 0.3);
            display: block;
          }
        }

        .gallery-card-footer {
          color: var(--primary-color);

          span {
            position: relative;
          }

          i {
            width: unset !important;
          }
        }

        .gallery-card-title {
          color: var(--primary-color) !important;
          width: 90%;
          transform: translateX(5%);
          padding: 25px 10px 25px 10px;
          border: none;
          text-transform: uppercase;
          letter-spacing: 0.2em;
        }
      }
    }

    .photographer-footer-bio img {
      max-width: 250px;
    }

    .share-view {
      background-image: none !important;

      h3 {
        text-transform: uppercase;
        margin-bottom: 3rem;
      }

      .rounded-background {
        background-color: unset;
      }

      .share-description {
        margin-right: -15px;
        text-transform: uppercase;

        hr {
          border-top: 1px solid black;
        }
      }
    }

    .share-ul a {
      color: var(--secondary-color);
    }

    li span,
    .select-wrapper select {
      color: var(--secondary-color);
    }

    .social-icons.popup-app {
      font-size: 17px;

      a:first-child {
        margin-left: unset;
      }
    }

    .like-view,
    .selections-view {
      h3 {
        text-transform: uppercase;
      }
    }

    .gallery-view .gallery-section,
    .selections-view .gallery-section,
    .selections-view .gallery-card-container {
      padding-top: 80px;
    }

    .gallery-view .gallery-text-wrapper {
      margin-top: 160px;
      margin-bottom: 0;
    }

    .photographer-view {
      .contact-profile-image .rounded-logo {
        background-color: var(--primary-color);
      }

      color: var(--secondary-color);

      .social-icons {
        font-size: 1.5em;

        a {
          div {
            background-color: unset;
            margin-right: 20px;
            height: auto;
            width: auto;
            color: var(--secondary-color);
          }

          i {
            font-size: unset;
          }
        }
      }

      footer .copyright {
        a {
          color: var(--secondary-color);
        }
      }

      ul {
        li {
          a {
            color: var(--secondary-color);
          }
        }
      }
    }
  }

  &.Breeze {
    $font: 'Cormorant Infant';
    --font: #{$font};
    font-weight: 300;

    @extend .SimpleFiligree;

    .photographer-info {
      transform: rotate(-90deg);
      white-space: pre;
      max-width: initial;
      @media all and (max-width: 770px) {
        transform: translate(0, 0);
      }
    }

    .slider {
      height: auto;

      .photographer-logo {
        display: none;
      }

      .slider-wrapper {
        $landscape-width: 100%;
        $landscape-height: 80%;
        $horizontal-width: 100%;
        $horizontal-height: 85%;

        transform: unset !important;
        transition: unset !important;
        position: relative;
        height: 100vh;
        width: 100vw;
        margin-top: 0;
        left: 0;

        @media all and (min-width: 991px) {
          margin-top: -100px;
        }

        .accurate-landscape {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: $landscape-width;
          height: $landscape-height;
          display: flex;
          flex-direction: column;
          @media all and (max-width: 770px) {
            height: 95%;
          }

          .image-wrap {
            display: flex;
            flex: 2;
            flex-direction: column;
            width: 100%;
            height: 100%;
            position: relative;
          }
        }

        .accurate-horizontal {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: $horizontal-width;
          height: $horizontal-height;
          display: flex;
          flex-direction: column;
          @media all and (max-width: 770px) {
            width: 90%;
            height: 95%;
          }

          .image-wrap {
            display: flex;
            flex: 3;
            flex-direction: column;
            width: 100%;
            height: 100%;
            position: relative;
          }

          .accurate-logo {
            @media all and (min-width: 770px) and (max-width: 1000px) {
              transform: translateY(10vh);
            }
          }

          .accurate-photographer-lg {
            transform: translateX(0);
          }
        }

        .accurate-logo {
          width: 60%;
          height: 20%;
          max-width: 250px;
          max-height: 130px;
          margin: 0 auto 15px auto;
          @media all and (min-width: 770px) {
            margin: 0 auto 20px auto;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .slide-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          @media all and (min-width: 770px) {
            flex-direction: row;
          }
          .accurate-photographer-lg {
            font-size: 14px;
            display: none;
            width: 200px;
            @media all and (min-width: 770px) {
              display: flex;
              justify-content: end;
              flex: 1;
            }
          }
          .accurate-content-lg {
            display: none;
            margin: 0px 30px 0px 30px;
            white-space: break-spaces;

            @media all and (min-width: 770px) {
              display: flex;
              flex-direction: column;
              flex: 1;
            }
            .accurate-title {
              font-size: 24px;
              font-weight: 300;
              font-style: italic;
            }
            hr {
              max-width: 100px;
            }
            .accurate-date {
              font-size: 14px;
            }
          }
          .shift-to-bottom {
            margin-bottom: -130px;
          }
          .accurate-content-sm {
            display: block;
            margin-top: 20px;
            padding: 0 5%;
            white-space: break-spaces;
            @media all and (min-width: 770px) {
              display: none;
            }
            .accurate-title {
              font-size: 22px;
              font-weight: 300;
              font-style: italic;
            }
            .accurate-date,
            .accurate-photograper-sm {
              font-size: 12px;
            }
          }
        }

        .image-wrap {
          @media all and (max-width: 770px) {
            width: 90% !important;
          }
        }

        .slide {
          transition: opacity 1s ease-in-out;
          @media all and (min-width: 770px) {
            width: 100%;
            height: 100%;
            background-position: center center !important;
            background-size: contain !important;
          }
        }

        .slide-logo-wrap {
          position: relative;
          display: flex;
          flex: 1;
        }
      }

      .photographer-company {
        position: absolute;
        writing-mode: vertical-lr;
        right: -35px;
        transform: rotate(180deg);

        h3 {
          margin: 0;
          text-transform: uppercase !important;
          color: var(--secondary-color);
        }
      }

      .slide-content {
        visibility: hidden;
        position: relative;
        display: block;
        height: 30px;
        @media all and (max-width: 769px) {
          height: 0;
        }
      }

      .slide-cta {
        position: relative;
        bottom: -5px;
        left: 0;
        transform: unset;
        display: block;
        margin-top: 25px;
        margin-bottom: 20px;

        i {
          font-size: 2.7rem;
        }

        .fa-chevron-down {
          color: var(--secondary-color);
        }

        @media all and (max-width: 992px) {
          bottom: 125px;
        }
        @media all and (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  &.VickyBaumannFineart {
    @include VickyBaumannFineart;
  }

  &.Capri {
    @include Capri;
  }

  &.Rose {
    @include Rose;
  }

  &.Calm {
    @include Calm;
  }

  $primaryTemplateColor: #c6868a !default;
  $imageHoverColor: rgba(197, 133, 137, 0.4) !default;
  $backgroundColorSpringVibes: #f3e7e7 !default;
  $hoverColorSpringVibes: #a95c61 !default;

  @mixin SpringVibes {
    $fontFamilySpringVibes: 'Bellota', cursive;
    --font: #{$fontFamilySpringVibes};

    .modal-single-image .close,
    .modal-single-image .loading {
      color: var(--primary-color);
    }

    .gallery-card-footer span {
      bottom: 5px;
    }

    .pswp__button--arrow--next {
      mask-image: url('../assets/images/icons/spring - arrow right.svg');
      -webkit-mask-image: url('../assets/images/icons/spring - arrow right.svg');
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: 50% 50%;
      -webkit-mask-position: 50% 50%;
      background-color: var(--primary-color) !important;
      background-image: none !important;
    }

    .pswp__button--arrow--prev {
      mask-image: url('../assets/images/icons/spring - arrow left.svg');
      -webkit-mask-image: url('../assets/images/icons/spring - arrow left.svg');
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-position: 50% 50%;
      -webkit-mask-position: 50% 50%;
      background-color: var(--primary-color) !important;
      background-image: none !important;
    }

    .photographer-info {
      font-size: 16px;
      margin-top: 25px;
    }

    .multi-select .gray {
      color: var(--primary-color);
    }

    .gallery-text-wrapper p {
      color: var(--primary-color);
    }

    .sb-btn-secondary {
      background-color: var(--secondary-color) !important;
      color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
    }

    hr {
      border-top: 1px solid white !important;
    }

    .slide.center-top-background-image {
      background-position: center top !important;
    }

    .sb-container {
      margin-right: auto;
      margin-left: auto;
      width: 100%;

      max-width: 1200px;

      @media screen and (min-width: 1400px) {
        max-width: 1340px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 1540px;
      }

      @media screen and (min-width: 2000px) {
        max-width: 1840px;
      }

      @media screen and (min-width: 2400px) {
        max-width: 2240px;
      }
    }

    .upselling-package-card {
      border-radius: 30px;
    }

    .modal-header {
      background-color: var(--page-bg);
      border-bottom: unset;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 100% 50px;
      width: 100%;
      height: fit-content;

      .modal-title {
        font-weight: 800 !important;
        font-size: 1.4rem !important;
        word-wrap: break-word !important;
        z-index: 2;
        position: relative;

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          -webkit-mask-image: url(../assets/templates/springvibes/bg-title-rose.png);
          mask-image: url(../assets/templates/springvibes/bg-title-rose.png);
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 40px;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          z-index: -1;
          background-color: var(--primary-color);
          @media all and (max-width: 500px) {
            -webkit-mask-size: 90% 100% !important;
            mask-size: 90% 100% !important;
          }
        }

        // background-image: url("../assets/templates/springvibes/bg-title-rose.png");
        // background-size: 100% 49px;
        padding: 4px 30px;
        background-repeat: no-repeat;
        margin: 0;
      }

      h5 {
        color: var(--secondary-color);
      }

      button {
        top: 1rem;
        right: 1rem;
        position: absolute;
      }
    }

    .modal-content {
      border: none;
      background-color: var(--page-bg);
      border-radius: 0;
    }

    .checkmark-wrapper {
      padding-top: 7px;
    }

    .modal-body,
    .share-ul {
      .fa-facebook-square:before {
        content: '\0045';
        display: inline-block;
        font-family: 'SpringVibes';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    .icon-close:before {
      content: '\0041';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-ban:before {
      content: '\004e';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-plus:before {
      content: '\0052';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-eye:before {
      content: '\004f';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-gift:before {
      content: '\0054';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-send:before {
      content: '\0055';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-check-circle:before {
      content: '\004e';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-circle:before {
      content: '\004d';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-chevron-left:before {
      content: '\0056';
      display: inline-block;
      font-family: 'VickySymbols';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-copy:before {
      content: '\0043';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .icon-cloud-download:before,
    .fa-download:before {
      content: '\0044';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-envelope:before {
      content: '\004a';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-home:before {
      content: '\0046';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-heart-o:before,
    .fa-heart:before {
      content: '\0048' !important;
      display: inline-block;
      font-family: 'SpringVibes' !important;
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      position: relative;
      top: 2px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-search-plus:before {
      content: '\0049';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .fa-share:before {
      content: '\004b';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .fa-address-card:before {
      content: '\004c';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .profile-dropdown-toggle {
      &:before {
        width: 22px;
        height: 22px;
        display: inline-block;
        content: '';
        -webkit-mask: url(../assets/images/account-spring-vibes.svg) no-repeat 50% 50%;
        mask: url(../assets/images/account-spring-vibes.svg) no-repeat 50% 50%;
        -webkit-mask-size: contain;
        mask-size: contain;
        background-color: black;
      }

      &:hover:before {
        background-color: var(--hover-color-spring-vibes);
      }
    }
    .fa-whatsapp:before {
      content: '\004d';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .fa-comment-o:before,
    .fa-comment:before {
      content: '\0042' !important;
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .fa-chevron-down:before {
      content: '\0041';
      display: inline-block;
      font-family: 'SimpleFiligree';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-image:before {
      content: '\0046';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      top: 3px;
      position: relative;
    }

    .fa-save:before {
      content: '\0053';
      display: inline-block;
      font-family: 'SpringVibes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      top: 3px;
      position: relative;
    }

    button.close {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.5;
      background-color: transparent;
      border: 0;
      z-index: 99999;

      span {
        display: none;
      }
    }

    .pswp__button--fs {
      background-image: url('../assets/templates/springvibes/fullscreenwhite.png') !important;
      background-size: 28px !important;
      background-position: center !important;
    }

    .pswp__button--close {
      background-image: url('../assets/templates/springvibes/close-white.png') !important;
      background-size: 24px !important;
      background-position: center !important;
    }

    .pswp__button--zoom {
      background-image: url('../assets/templates/springvibes/lupe-plus-white.png') !important;
      background-size: 24px !important;
      background-position: center !important;
    }

    .SpringVibes {
      font-family: var(--font);
      letter-spacing: 0.1em;
      background-color: var(--page-bg);
      min-height: 100vh;
    }

    font-family: var(--font);
    background-color: var(--page-bg);
    min-height: 100vh;

    .sb-btn.sb-btn-icon .fa {
      top: 3px;
      position: relative;
    }

    .sb-btn i {
      text-transform: none !important;
    }

    .sb-btn,
    .download-selection-modal .modal-content a {
      text-align: center;
      background-color: var(--primary-color);
      color: var(--secondary-color);
      border-radius: 30px;
      border: 3px solid var(--primary-color);

      svg {
        fill: var(--secondary-color);
        stroke: var(--secondary-color);
      }
    }

    .sb-btn:hover,
    .download-selection-modal .modal-content a:hover {
      border-color: var(--hover-color-spring-vibes) !important;
    }

    .sb-btn-no-hover {
      @extend .sb-btn;
      .no-hover-text {
        margin-left: 10px;
      }
    }

    .selection-button-group {
      button {
        border-color: var(--hover-color-spring-vibes);
        color: var(--secondary-color);
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--font);
    }

    button,
    .gallery-card-footer span {
      font-family: var(--font);
    }

    .fixed-sharing-button,
    .fixed-gift-button,
    .fixed-shop-button {
      .btn-circle {
        background-color: var(--primary-color);
        border: 0 solid;

        .fa {
          color: var(--secondary-color);
        }
      }
    }

    .transparent-overlay {
      display: none;
    }

    .share-ul i {
      top: 6px;
      position: relative;
    }

    .share-description h4 {
      font-size: 1.15rem;
    }

    .slider {
      .photographer-logo {
        display: none;
      }

      .slide-cta {
        color: var(--secondary-color);
      }

      .slide-title {
        position: absolute;
        width: 90%;
        top: calc(50% - 32px);
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: var(--primary-color);
        margin-bottom: 0;
        max-height: 90%;
        text-overflow: ellipsis;
        overflow: hidden;
        text-transform: unset;
        font-size: 2rem;

        h2 {
          color: var(--primary-color);
        }
      }

      @media screen and (min-width: 992px) {
        .slide-title {
          top: 50%;
          font-size: 2.5rem;
        }
      }

      .slide-content {
        bottom: 45%;
      }

      .title-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 325px;
        width: 325px;
        height: 230px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-image: url('../assets/templates/springvibes/slider-mobile-small.png');

        &.disable-decorations {
          background-image: url('../assets/templates/springvibes/slider-web.png');

          .slide-title {
            top: 50% !important;
          }
        }
      }

      @media screen and (min-width: 768px) {
        .title-background {
          background-size: 400px;
          width: 400px;
          height: 280px;

          &.disable-decorations {
            background-image: url('../assets/templates/springvibes/slider-web.png');
          }
        }
      }

      @media screen and (min-width: 992px) {
        .title-background {
          height: 215px;
          background-image: url('../assets/templates/springvibes/slider-web.png');
        }
      }

      @media screen and (max-width: 768px) {
        .title-background {
          background-image: url('../assets/templates/springvibes/slider-mobile-small.png');
        }
      }
    }

    @media screen and (min-width: 992px) {
      .ornament-container {
        .left {
          background-image: url('../assets/templates/springvibes/flower-left-web.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 250px;
          height: 245px;
          width: 250px;
          bottom: -25px;
          position: absolute;
        }

        .right {
          background-image: url('../assets/templates/springvibes/flower-right-web.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 200px;
          height: 305px;
          width: 200px;
          bottom: -25px;
          right: 0;
          position: absolute;
        }
      }
    }

    .selection-bar {
      background-color: var(--primary-color);
      padding-bottom: 15px;
      color: var(--secondary-color);
    }

    #desktop-nav {
      background-color: var(--primary-color);
    }

    .nav-desktop {
      .gallery-selection .gallery-button:hover,
      .nav-actions .actions i:hover {
        color: var(--hover-color-spring-vibes);
      }

      background-color: var(--primary-color);

      .gallery-selection .gallery-button {
        color: var(--secondary-color);
        border-radius: 30px;
        border: 1px solid var(--secondary-color);
        font-size: 17px;

        &:hover {
          border: 1px solid var(--hover-color-spring-vibes);
        }
      }

      .nav-actions {
        color: var(--secondary-color);

        .actions i {
          font-size: 25px;
        }
      }

      .fa-caret-right,
      .fa-caret-left {
        color: var(--secondary-color);
      }
    }

    .nav-bottom-sticky {
      position: fixed;
      z-index: 100;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      background-color: var(--primary-color);
      line-height: 25px;
      color: var(--secondary-color);

      hr {
        margin: 0 15px 0 15px !important;
      }
    }
    .nav-top-sticky-wrapper {
      padding: 20px 0 20px;
      background-color: var(--primary-color);
      position: fixed;
      z-index: 100;
      width: 100%;
      top: 0;

      .nav-top-sticky {
        position: fixed;
        font-size: 20px;
        z-index: 999;
        background-color: var(--primary-color);
        width: 100%;
        color: var(--secondary-color);

        .nav-top-sticky-title {
          letter-spacing: 0.2em;
          font-weight: 500;
          font-family: var(--font);
        }
      }
    }

    .social-icons a div:hover {
      i {
        color: var(--hover-color-spring-vibes) !important;
      }
      svg {
        fill: var(--hover-color-spring-vibes);
        stroke: var(--hover-color-spring-vibes);
      }
    }

    .sb-gallery-image-hover {
      &:hover {
        background: linear-gradient(0deg, var(--image-hover-color), rgba(0, 0, 0, 0) 40%);
      }

      .hover-action-container i.fa:hover {
        color: var(--hover-color-spring-vibes);
      }

      .hover-action-container .gallery-eye-icon:hover {
        color: var(--hover-color-spring-vibes);
      }

      .hover-action-container i {
        color: var(--secondary-color);
      }
    }

    .gallery-card-container {
      .gallery-card {
        .gallery-card-image {
          border-radius: 0;

          .sharing {
            border: 0;

            .gallery-sharing-title {
              color: black;
              letter-spacing: 0.1em;

              i {
                color: var(--primary-color);
              }
            }

            .transparent-overlay {
              display: none;
            }

            height: 100%;
            background-color: white;
            color: black;

            .gallery-sharing-links {
              color: black;

              .share-loader .spinner-grow {
                color: var(--primary-color);
              }
            }
          }

          .transparent-overlay {
            display: none;
          }
        }

        .gallery-card-footer {
          color: var(--secondary-color);

          span {
            position: relative;
          }

          i {
            width: unset !important;
          }
        }

        .gallery-card-title-background {
          width: 275px;
          height: 110px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);

          z-index: 2;

          &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            -webkit-mask-image: url(../assets/templates/springvibes/bg-title-blue.png);
            mask-image: url(../assets/templates/springvibes/bg-title-blue.png);
            -webkit-mask-size: 275px;
            mask-size: 275px;
            -webkit-mask-position: center;
            mask-position: center;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            z-index: -1;
            background-color: var(--primary-color);
          }

          .gallery-card-title {
            color: var(--secondary-color);
            width: 90%;
            border: none;
            letter-spacing: 0.1em;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            bottom: unset;
            padding: unset;
            margin-bottom: 0;
          }
        }
      }
    }

    .photographer-footer-bio img {
      max-width: 250px;
    }

    .share-view {
      background-image: none !important;

      .share-view-title-background {
        width: 165px;
        height: 75px;
        margin: 0 auto 3rem auto;
        position: relative;

        z-index: 2;

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          -webkit-mask-image: url(../assets/templates/springvibes/bg-title-blue.png);
          mask-image: url(../assets/templates/springvibes/bg-title-blue.png);
          -webkit-mask-size: 165px;
          mask-size: 165px;
          -webkit-mask-position: center;
          mask-position: center;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          z-index: -1;
          background-color: var(--primary-color);
        }

        h3 {
          color: var(--secondary-color);
          width: 90%;
          border: none;
          letter-spacing: 0.1em;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          bottom: unset;
          padding: unset;
          margin: 5% 0;
        }
      }

      .rounded-background {
        background-color: unset;
      }

      .share-description {
        padding-left: 15px;

        h4 {
          margin: 0;
        }

        hr {
          display: none;
        }
      }
    }

    .share-ul {
      i {
        color: var(--primary-color);
      }

      a {
        color: black;
      }
    }

    .select-wrapper select {
      color: var(--primary-color);
    }

    #app,
    .gallery-view,
    .selections-view,
    .like-view,
    .share-view,
    .photographer {
      background-color: var(--page-bg-color);
    }

    .gallery-view .gallery-section,
    .selections-view .gallery-section,
    .selections-view .gallery-card-container {
      padding-top: 80px;
    }

    .gallery-view .gallery-text-wrapper {
      margin-top: 160px;
      margin-bottom: 0;
    }

    .photographer .imprint-container {
      color: var(--modal-font-color);

      a {
        color: var(--modal-font-color);
      }
    }

    .photographer-view {
      .contact-profile-image .rounded-logo {
        background-color: var(--primary-color);
      }

      background-color: var(--page-bg);
      .social-icons {
        font-size: 1.5em;

        a {
          div {
            background-color: unset;
            border-radius: 0;
            width: auto;
            margin-right: 20px;
            height: auto;
            color: var(--secondary-color);
            display: flex;
          }

          i {
            font-size: unset;
          }
        }
      }

      footer .copyright {
        padding: 0;
        font-size: 1em;

        a {
          margin: 0 5px;
          color: var(--secondary-color);

          &:hover {
            color: var(--hover-color-spring-vibes);
            text-decoration: none;
          }
        }
      }

      background-color: var(--page-bg);

      .footer-title {
        color: var(--primary-color);
      }

      ul {
        li {
          color: var(--primary-color);

          span:first-of-type {
            font-family: var(--font);
          }

          a {
            color: var(--primary-color);
            &:hover {
              color: $hoverColorSpringVibes;
            }
          }
        }
      }

      .social-icons {
        margin-top: 20px;
        margin-bottom: 30px;

        a div {
          color: var(--primary-color);
        }
      }
    }
  }

  &.SpringVibesRose {
    //colors sind die defaults weiter oben
    --primary-color: #{$primaryTemplateColor};
    --secondary-color: white;
    --image-hover-color: #{$imageHoverColor};
    --hover-color-spring-vibes: #{$hoverColorSpringVibes};
    --page-bg: #{$backgroundColorSpringVibes};

    @include SpringVibes;
  }

  &.SpringVibesBlue {
    $primaryTemplateColor: #9ac0ca;
    $imageHoverColor: rgba(154, 192, 202, 0.4);
    $backgroundColorSpringVibes: #f0f6f7;
    $hoverColorSpringVibes: #5f929e;
    --primary-color: #{$primaryTemplateColor};
    --secondary-color: #{$backgroundColorSpringVibes};
    --image-hover-color: #{$imageHoverColor};
    --hover-color-spring-vibes: #{$hoverColorSpringVibes};
    --page-bg: #{$backgroundColorSpringVibes};
    @include SpringVibes;

    .modal-header .modal-title {
      z-index: 2;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-mask-image: url(../assets/templates/springvibes/bg-title-blue.png);
        mask-image: url(../assets/templates/springvibes/bg-title-blue.png);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 49px;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        z-index: -1;
        background-color: var(--primary-color);
        @media all and (max-width: 500px) {
          -webkit-mask-size: 90% 100% !important;
          mask-size: 90% 100% !important;
        }
      }
    }

    .gallery-card-title-background,
    .photographer-view-title-background,
    .share-view-title-background {
      z-index: 2;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-mask-image: url(../assets/templates/springvibes/bg-title-blue.png);
        mask-image: url(../assets/templates/springvibes/bg-title-blue.png);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        z-index: -1;
        background-color: var(--primary-color);
        @media all and (max-width: 500px) {
          -webkit-mask-size: 90% 100% !important;
          mask-size: 90% 100% !important;
        }
      }
    }
  }

  &.SpringVibesYellow {
    $primaryTemplateColor: #e8bb41;
    $imageHoverColor: rgba(232, 187, 65, 0.4);
    $backgroundColorSpringVibes: #fcf5e3;
    $hoverColorSpringVibes: #c69613;
    --primary-color: #{$primaryTemplateColor};
    --secondary-color: #{$backgroundColorSpringVibes};
    --image-hover-color: #{$imageHoverColor};
    --hover-color-spring-vibes: #{$hoverColorSpringVibes};
    --page-bg: #{$backgroundColorSpringVibes};
    @include SpringVibes;

    .modal-header .modal-title {
      z-index: 2;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-mask-image: url(../assets/templates/springvibes/bg-title-yellow.png);
        mask-image: url(../assets/templates/springvibes/bg-title-yellow.png);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        z-index: -1;
        background-color: var(--primary-color);
        @media all and (max-width: 500px) {
          -webkit-mask-size: 90% 100% !important;
          mask-size: 90% 100% !important;
        }
      }
    }

    .gallery-card-title-background,
    .photographer-view-title-background,
    .share-view-title-background {
      z-index: 2;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-mask-image: url(../assets/templates/springvibes/bg-title-yellow.png);
        mask-image: url(../assets/templates/springvibes/bg-title-yellow.png);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        z-index: -1;
        background-color: var(--primary-color);
        @media all and (max-width: 500px) {
          -webkit-mask-size: 90% 100% !important;
          mask-size: 90% 100% !important;
        }
      }
    }
  }

  &.ShopNew {
    @include NewShop;
  }
}

.rmsc {
  --rmsc-main: var(--primary-color) !important;
  --rmsc-hover: #fff;
  --rmsc-selected: #fff;
  --rmsc-border: #ccc;
  --rmsc-gray: var(--primary-color) !important;
  --rmsc-bg: #fff;
  --rmsc-p: 3px !important; /* Spacing */
  --rmsc-radius: 0px; /* Radius */
  --rmsc-h: 38px; /* Height */

  .dropdown-content {
    z-index: 1000 !important;
  }
}

.multi-select {
  .dropdown-container,
  .dropdown-heading {
    cursor: pointer !important;
  }

  .dropdown-container:focus-within {
    box-shadow: unset !important;
  }
}

// .gallery-eye-icon:hover {
//   color: red;
// }

.gallery-eye-icon:focus {
  outline: none;
}
