@mixin VickyBaumannFineart {
  $font: 'Cochin';
  $headingFont: 'Cochin';
  $handWritingFont: 'HandWriting', serif;
  $titleColor: black;
  $subtitleColor: #c3a78c;
  $vickyBeige: #c3a78c;
  $vickyBeigeHover: #865e42;
  $stickyBarColor: white;
  $backgroundColor: white;
  --font: #{$font};
  --heading-font: #{$headingFont};
  --primary-color: white;
  --secondary-color: black;
  --decorations-color: #{$vickyBeige};

  .photographer-info {
    margin: 20px 0;
    color: var(--secondary-color);
    transform: translateY(30px);
  }

  .rmsc {
    --rmsc-gray: var(--secondary-color) !important;
  }

  .sb-btn-secondary {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border-color: var(--primary-color);
    border-radius: 0;
  }

  &.disable-decorations {
    .slide-sub-title,
    .gallery-seperator {
      display: none !important;
    }

    .slide-title {
      margin: 0 !important;
    }

    .social-icons a div:before {
      content: '' !important;
      display: none;
    }

    .share-view-title-wrapper img {
      display: none !important;
    }
  }

  .slide-subtitle {
    font-family: $handWritingFont;
  }

  .add-images-to-collection {
    margin-top: 10px;
  }

  @media screen and (max-width: 991px) {
    .white-border-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 14px 14px 20px 14px;
      z-index: 9;

      .white-border {
        width: 100%;
        height: 100%;
        border: 1px solid white;
      }
    }
  }

  .slider-divider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    margin-top: -15px;
    position: relative;
    padding-top: 30px;
    .slider-image-wrap {
      position: absolute;
      background-color: var(--primary-color);
      .slider-divider-image {
        margin: 0 10px;
        width: 73px;
        height: 14px;
        -webkit-mask-image: url(assets/images/moon-phases.png);
        mask-image: url(assets/images/moon-phases.png);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        background-color: var(--secondary-color);
      }
    }

    img {
      margin: 0 10px;
    }

    .left-line,
    .right-line {
      height: 1px;
      flex-grow: 1;
      background-color: var(--secondary-color);
    }
  }

  @media screen and (max-width: 991px) {
    .contact-studio-photo-wrapper {
      width: 110px;
      height: 110px;
    }
  }

  @media screen and (min-width: 992px) {
    .contact-studio-photo-wrapper {
      width: 200px;
      height: 200px;
    }
  }

  .contact-studio-photo-wrapper {
    padding: 2px;
    border: 1px solid var(--secondary-color);
    border-radius: 50%;
  }

  .contact-studio-photo {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }

  .contact-photos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .contact-images-divider {
      height: 120px;
      width: 1px;
      background-color: var(--secondary-color);
      align-self: stretch;
      margin: 0 30px;
    }

    .contact-logo {
      width: 110px;
    }
  }

  .gallery-view .photographer-footer-bio {
    padding-bottom: 0px;
  }

  .gallery-view .gallery-card {
    padding: 5px 5px 0 5px !important;
  }

  .gallery-section-divider {
    -webkit-mask-image: url(assets/images/gallery-divider.png);
    mask-image: url(assets/images/gallery-divider.png);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    background-color: var(--primary-color);
    height: 30px;
    margin: -10px 0;
    position: relative;
    z-index: 9;
  }

  @media screen and (max-width: 991px) {
    .gallery-section-divider {
      margin: -10px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .gallery-section-divider {
      margin: -20px 0;
    }

    #desktop-nav {
      margin-bottom: 5px;
    }
  }

  .gallery-text-wrapper p {
    color: var(--secondary-color);
  }

  .input-element {
    background: white !important;
  }

  .selection-bar {
    background-color: var(--primary-color);

    &.sticky {
      padding-bottom: 15px;
    }
  }

  .VickyBaumannFineart {
    font-family: var(--font), sans-serif;
    background-color: var(--primary-color);
    min-height: 100vh;
  }

  font-family: var(--font), sans-serif;
  background-color: var(--primary-color);
  min-height: 100vh;
  color: var(--secondary-color);

  .sb-select,
  .select-wrapper {
    select {
      color: var(--secondary-color) !important;
      border-bottom-color: var(--secondary-color) !important;
    }

    &:after {
      color: var(--secondary-color) !important;
    }
  }

  .sb-btn,
  .download-selection-modal .modal-content a {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-color: var(--secondary-color);
    border-radius: 0;
    border: solid 1px;
    align-items: center;
  }

  .sb-btn:hover,
  .download-selection-modal .modal-content a:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-color: var(--secondary-color);

    .fa-address-card::before {
      background-color: var(--primary-color);
    }
  }

  .sb-btn-no-hover {
    @extend .sb-btn;
    .no-hover-text {
      height: 20px;
      margin-left: 10px;
    }
    &:hover {
      background-color: var(--primary-color) !important;
      color: var(--secondary-color) !important;
      border-color: var(--secondary-color) !important;
      border-radius: 0 !important;
      align-items: center !important;
    }
  }

  .sb-indicator {
    background-color: var(--hover-color);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--heading-font), serif;
  }

  button,
  .gallery-card-footer span {
    font-family: var(--font), sans-serif;
  }

  .fixed-sharing-button,
  .fixed-gift-button,
  .fixed-shop-button {
    .fa-shopping-cart:before {
      background-color: var(--secondary-color);
      width: 25px;
      height: 20px;
    }

    .btn-circle {
      background-color: var(--primary-color);
      border: 1px solid var(--secondary-color);

      .fa {
        color: var(--secondary-color);
      }
    }
  }

  .slider-wrapper {
    transform: unset !important;
    transition: unset !important;

    .slide {
      position: absolute;
      left: 0;
      top: 0;
      transition: opacity 1s ease-in-out;
    }
  }

  .pswp__button--fs {
    background-image: url('assets/templates/vickybaumannboho/full-screen.png') !important;
    background-size: unset !important;
    background-position: center !important;
  }

  .pswp__button--zoom {
    background-image: url('assets/templates/vickybaumannboho/search.png') !important;
    background-size: unset !important;
    background-position: center !important;
  }

  .pswp__button--close {
    background-image: url('assets/templates/vickybaumannboho/close.png') !important;
    background-size: unset !important;
    background-position: center !important;
  }

  .pswp__button--arrow--next {
    mask-image: url('../assets/images/icons/vicky fineart - arrow right.svg');
    -webkit-mask-image: url('../assets/images/icons/vicky fineart - arrow right.svg');
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: 50% 50%;
    -webkit-mask-position: 50% 50%;
    background-color: var(--secondary-color) !important;
    background-image: none !important;
  }

  .pswp__button--arrow--prev {
    mask-image: url('../assets/images/icons/vicky fineart - arrow left.svg');
    -webkit-mask-image: url('../assets/images/icons/vicky fineart - arrow left.svg');
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: 50% 50%;
    -webkit-mask-position: 50% 50%;
    background-color: var(--secondary-color) !important;
    background-image: none !important;
  }

  .fixed-sharing-button .fa-share {
    font-size: 20px;
  }

  .imprint-container {
    background-color: var(--primary-color);
    margin-top: 1.1em;
    padding-bottom: 100px;
    color: black;

    a {
      color: var(--secondary-color);
    }
  }

  .gallery-section {
    margin-bottom: 20px;
  }

  .my-account-button {
    display: flex;
    align-items: center;
  }

  .sb-gallery-image {
    max-height: 100%;
  }

  .comment-badge {
    background-color: var(--secondary-color);
  }

  .checkmark-wrapper {
    padding-top: 5px;
  }

  .photoswipe-like-button-liked .photoswipe-custom-button-like {
    color: #ff4c4c;
  }

  .sb-gallery-image-hover .hover-action-container {
    i.fa:hover,
    .gallery-eye-icon:hover {
      color: #d5b097;
    }

    i.liked-image {
      color: var(--primary-color);
    }

    i {
      color: var(--primary-color);
    }
  }

  .fa-twitter:before {
    content: '\004d';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-close:before {
    content: '\0041';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .vicky-symbols-arrow:before {
    content: '\0042';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-comment-o:before,
  .fa-comment:before {
    content: '\0043';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-facebook-square:before,
  .fa-facebook:before {
    content: '\0047';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-whatsapp:before {
    content: '\004f';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-home:before,
  .fa-image:before {
    content: '\0048';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-copy:before {
    content: '\0044';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-cloud-download:before,
  .fa-download:before {
    content: '\0045';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-envelope:before {
    content: '\0046';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-heart-o:before,
  .fa-heart:before {
    content: '\0049';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-search-plus:before {
    content: '\004a';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-share:before {
    content: '\004c';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-pinterest:before {
    content: '\004b';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-address-card:before {
    width: 28px;
    height: 20px;
    display: inline-block;
    content: '';
    -webkit-mask: url(assets/images/photographer-vicky.svg) no-repeat 50% 50%;
    mask: url(assets/images/photographer-vicky.svg) no-repeat 50% 50%;
    -webkit-mask-size: contain;
    mask-size: contain;
    background-color: var(--secondary-color);
  }

  .fa-shopping-cart:before {
    width: 17px;
    height: 15px;
    display: inline-block;
    content: '';
    -webkit-mask: url(assets/images/shopping-cart-fineart.svg) no-repeat 50% 50%;
    mask: url(assets/images/shopping-cart-fineart.svg) no-repeat 50% 50%;
    background-color: var(--secondary-color);
    -webkit-mask-size: contain;
    mask-size: contain;
  }

  .fa-plus:before {
    width: 17px;
    height: 15px;
    display: inline-block;
    content: '';
    -webkit-mask: url(assets/images/plus-fineart.svg) no-repeat 50% 50%;
    mask: url(assets/images/plus-fineart.svg) no-repeat 50% 50%;
    background-color: var(--secondary-color);
    -webkit-mask-size: contain;
    mask-size: contain;
  }

  @media screen and (max-width: 991px) {
    .fa-address-card:before {
      background-color: var(--secondary-color);
    }
  }

  @media screen and (min-width: 992px) {
    .fa-address-card:before {
      background-color: var(--secondary-color);
    }
  }

  .profile-dropdown-toggle:before {
    content: '\004e';
    display: inline-block;
    color: var(--secondary-color);
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-info:before {
    content: '\0050';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-gift:before {
    content: '\0051';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-youtube:before {
    content: '\0055';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-instagram:before {
    content: '\0052';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-check-circle:before {
    content: '\0054';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-circle:before {
    content: '\0053';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .fa-chevron-left:before {
    content: '\0056';
    display: inline-block;
    font-family: 'VickySymbols';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .nav-bottom-sticky {
    position: fixed;
    height: 55px;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: var(--primary-color);
    line-height: 25px;
    color: var(--secondary-color);
    border-top: 1px solid var(--secondary-color);

    .fa-address-card::before {
      background-color: var(--secondary-color);
      height: 32px;
      width: 32px;
    }
  }

  .nav-top-sticky {
    position: fixed;
    font-size: 20px;
    z-index: 999;
    width: 100%;
    color: var(--secondary-color);
    background-color: var(--primary-color);

    .nav-top-sticky-title {
      letter-spacing: 0.1em;
      font-weight: 400;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    i {
      padding: 17px 25px 17px 10px;
    }
  }

  .photographer-footer-bio {
    img {
      max-width: 220px;
    }
  }

  .share-ul a {
    color: var(--primary-color);
  }

  .share-ul {
    .dropdown-container {
      border-bottom: solid 1px var(--secondary-color);
    }
  }

  @media screen and (max-width: 991px) {
    .slider .slide-content {
      -webkit-mask-image: url(assets/images/vicky-baumann-title-background.png);
      mask-image: url(assets/images/vicky-baumann-title-background.png);
      -webkit-mask-size: 100% 100%;
      mask-size: 100% 100%;
      background-color: var(--primary-color);
    }
  }

  @media screen and (min-width: 992px) {
    .slider .slide-content {
      -webkit-mask-image: url(assets/images/title-background-desktop.png);
      mask-image: url(assets/images/title-background-desktop.png);
      -webkit-mask-size: 100% 100%;
      mask-size: 100% 100%;
      background-color: var(--primary-color);
    }
  }

  .slider {
    .slide-content {
      padding: 50px 0;
      bottom: 5vh;

      .title-background {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .vicky-baumann-slide-divider {
        width: 80px;
        height: 1px;
        background-color: var(--secondary-color);
        border: none;
      }

      .slide-sub-title {
        color: var(--decorations-color);
        font-family: $handWritingFont;
      }

      .slide-title {
        color: var(--secondary-color);
        font-size: 30px;
        text-transform: uppercase;
        white-space: normal;
        font-weight: 400;
        letter-spacing: 0.4em;
        margin-bottom: 1rem;
      }

      .slide-sub-title {
        font-weight: 400 !important;
        letter-spacing: 0;
        margin-bottom: 10px;
        line-height: 0.5;
      }
    }

    @media screen and (max-width: 991px) {
      .slide-content {
        bottom: 14vh;
      }

      .vicky-baumann-slide-divider {
        margin-top: 20px !important;
      }

      .slide-sub-title {
        font-size: 3.5rem !important;
      }

      .slide-title {
        font-size: 25px !important;
        letter-spacing: 0.1em !important;
      }

      .slide-date {
        font-size: 0.9rem;
      }
    }

    @media screen and (min-width: 992px) {
      .slide-content {
        bottom: 0;
      }

      .slide-sub-title {
        font-size: 5rem !important;
      }

      .vicky-baumann-slide-divider {
        margin-top: 36px !important;
      }
    }

    .title-image {
      margin-bottom: 15px;
      height: 75px;
    }

    .slide-cta {
      display: none;
    }

    .photographer-logo {
      background-color: unset !important;
      border-radius: unset !important;
      height: auto !important;

      display: block;
      max-width: 220px;
      width: unset;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: 25px;
      z-index: 99;

      img {
        max-width: 100%;
        height: auto;
        transform: unset !important;
        padding: unset !important;
        top: unset !important;
      }
    }
  }

  .gallery-card-container {
    .gallery-card {
      padding: 0;

      .gallery-card-image {
        border-radius: 0;

        .sharing-content {
          padding: 15px;
        }

        .gallery-sharing-title {
          display: flex;
          justify-content: space-between;
        }

        .sharing .gallery-sharing-title {
          color: var(--primary-color);
          text-transform: uppercase;
          letter-spacing: 0.1em;
        }

        .transparent-overlay {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }

      .gallery-card-footer {
        color: var(--primary-color);
        padding-bottom: 20px;

        span {
          bottom: -16px;
          position: relative;
        }

        i {
          width: unset !important;
        }
      }

      .gallery-card-title {
        color: var(--primary-color);
        width: 90%;
        bottom: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 25px 10px 25px 10px;
        border: solid 1px;
        text-transform: uppercase;
        letter-spacing: 0.2em;
      }
    }
  }

  .modal {
    .modal-header {
      border-bottom: 0;
      color: var(--secondary-color);

      button {
        margin-left: 0 !important;
      }

      .modal-title {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .info-modal-title .modal-title {
      font-size: 1.3rem !important;
      text-transform: unset;
    }

    .modal-content {
      border-radius: 0 !important;
    }
  }

  .like-view h3,
  .selections-view h3 {
    color: var(--secondary-color);
    text-transform: uppercase;
  }

  .share-view {
    .transparent-overlay {
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute !important;
    }

    .container {
      position: absolute;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
    }

    .rounded-background {
      background-color: var(--primary-color);
      border-radius: 0;
    }

    .rounded-background:first-child {
      margin-top: 3rem;
    }

    .share-ul {
      li {
        span,
        select {
          color: var(--secondary-color);
        }

        .fa {
          color: var(--secondary-color);
        }
      }
    }
  }

  .share-loader {
    color: var(--secondary-color);
  }

  .share-view-title-wrapper {
    text-align: center;
    padding: 10px;
    text-transform: uppercase;

    img {
      height: 50px;
      margin-bottom: 5px;
    }

    h3 {
      color: var(--secondary-color);
    }
  }

  .photographer-view {
    .photograpgher-contact-heading {
      font-family: $handWritingFont;
      font-size: 50px;
    }

    .contact-profile-image .rounded-logo {
      background-color: var(--primary-color);
    }

    a {
      color: var(--secondary-color) !important;
    }

    .user-name {
      font-family: var(--heading-font) !important;
    }

    .container {
      h3 {
        color: var(--secondary-color);
      }

      ul {
        li {
          a {
            color: var(--secondary-color);
          }
        }
      }
    }

    .social-icons {
      margin-bottom: 0;

      a div {
        background-color: unset;
        color: var(--secondary-color);
        border-radius: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      i {
        z-index: 1;
      }

      $icon-backgrounds: (
        1: 'assets/templates/vickybaumannboho/SocialIcons-BG1.png',
        2: 'assets/templates/vickybaumannboho/SocialIcons-BG2.png',
        3: 'assets/templates/vickybaumannboho/SocialIcons-BG3.png',
        4: 'assets/templates/vickybaumannboho/SocialIcons-BG4.png',
        5: 'assets/templates/vickybaumannboho/SocialIcons-BG3.png'
      );

      @for $i from 1 through 5 {
        a:nth-child(#{$i}) div {
          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 50px;
            height: 50px;
            -webkit-mask-image: url(map-get($icon-backgrounds, $i));
            mask-image: url(map-get($icon-backgrounds, $i));
            -webkit-mask-size: 45px;
            mask-size: 45px;
            -webkit-mask-position: center;
            mask-position: center;
            background-color: var(--decorations-color);
            -webkit-mask-repeat: no-repeat !important;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .gallery-selection {
      width: 800px !important;
    }
  }

  @media screen and (min-width: 1400px) {
    .gallery-selection {
      width: 950px !important;
    }
  }

  @media screen and (min-width: 1700px) {
    .gallery-selection {
      width: 1300px !important;
    }
  }

  .nav-desktop {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    height: 60px;
    margin-bottom: 7px;

    .col-12 {
      height: 35px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: auto;

      .gallery-selection-overflow.left {
        left: -15px;
      }
    }

    .gallery-button {
      color: var(--secondary-color);

      &:hover {
        .fa-shopping-cart:before {
          background-color: #aeaeae;
        }
      }
    }

    .gallery-selection {
      text-align: center;
      text-transform: uppercase;
      position: relative;
      width: 550px;

      .gallery-button {
        border: 1px solid;
        border-radius: 0;
      }
    }

    .nav-actions {
      position: absolute;
      right: 0px;
    }

    .fa-heart {
      top: 2px;
      position: relative;
      margin-right: 2px;
    }
  }

  .footer-logo {
    height: auto;
    margin-bottom: 20px;
    width: 250px;
    border-radius: unset;

    img {
      transform: unset;
      top: unset;
      padding: 15px;
    }
  }

  .modal-content {
    border-radius: 0 !important;
    border: 0px;
  }

  .gallery-seperator {
    display: none;
  }

  @media screen and (max-width: 991px) {
    .gallery-seperator {
      padding: 30px 0;
      margin-top: -10px;
      margin-bottom: -10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      text-align: center;
      color: var(--primary-color);
      z-index: 9;
      -webkit-mask-image: url(assets/images/background-brown.png);
      mask-image: url(assets/images/background-brown.png);
      -webkit-mask-size: 100% 160%;
      mask-size: 100% 160%;
      background-color: var(--decorations-color);

      img {
        width: 180px;
        margin-bottom: 30px;
        margin-top: 15px;
      }

      .gallery-separator-text-first-part {
        text-transform: uppercase;
        font-size: 25px;
        letter-spacing: 4px;
      }

      span.special-font {
        font-family: $handWritingFont;
        font-size: 70px;
        font-weight: 300;
      }
    }
  }

  button.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    background-color: transparent;
    border: 0;
  }

  @media screen and (min-width: 992px) {
    .slider {
      .slide-content {
        padding: 80px 0 30px 0;
      }
      .slide-attachment-fixed {
        background-attachment: fixed;
      }
    }

    .modal .modal-header .modal-title {
      font-weight: 600 !important;
      letter-spacing: 0.1em;
    }

    .sb-btn:hover,
    .download-selection-modal .modal-content a:hover {
      background-color: var(--primary-color);
      color: var(--secondary-color);
      border-color: var(--secondary-color);
      border-radius: 0;
    }
  }
}
